import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as FlexWebChat from '@twilio/flex-webchat-ui'
import * as Actions from '../states/webChat'

const configuration = {
  accountSid: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
  flexFlowSid: process.env.REACT_APP_TWILIO_FLEX_FLOW_SID,
  startEngagementOnInit: false,
  context: {
    topic: 'General',
    worker: localStorage.worker || null,
  },
  preEngagementConfig: {
    description: 'Hey there!',
    fields: [
      {
        label: 'What is your name?',
        type: 'InputItem',
        attributes: {
          name: 'friendlyName',
          type: 'text',
          placeholder: 'Name',
          required: false,
        },
      },
      {
        label: 'What is your phone number?',
        type: 'InputItem',
        attributes: {
          name: 'customerNumber',
          type: 'text',
          placeholder: 'Number',
          required: false,
          value: localStorage.number || null,
        },
      },
      {
        label: 'How can we help you?',
        type: 'TextareaItem',
        attributes: {
          name: 'question',
          type: 'text',
          placeholder: 'Type your question here',
          required: false,
          rows: 5,
        },
      },
    ],
    submitLabel: "Ok Let's Go!",
  },
}

class WebChat extends PureComponent {
  setupChat = () => {
    console.log('init webchat')
    this.props.init()
    if (this.props.currentUser)
      configuration.context.worker = this.props.currentUser.email
    if (this.props.customer)
      configuration.preEngagementConfig.fields[0].attributes.value =
        this.props.customer.firstName + ' ' + this.props.customer.lastName
    if (this.props.customer && this.props.customer.primaryPhone)
      configuration.preEngagementConfig.fields[1].attributes.value =
        this.props.customer.primaryPhone

    FlexWebChat.createWebChat(configuration).then((webchat) => {
      const { manager } = webchat

      FlexWebChat.Actions.addListener('afterStartEngagement', (payload) => {
        let state = manager.store.getState()
        // console.log('flex manager state', state)
        const { question } = payload.formData
        if (!question) {
          return
        }

        const { channelSid } = state.flex.session
        if (!channelSid) return
        manager.chatClient
          .getChannelBySid(channelSid)
          .then((channel) => channel.sendMessage(question))
      })

      manager.strings.PredefinedChatMessageBody =
        'The next available Customer Services agent will be with you shortly.'
      webchat.init()
      // console.log('started webchat')
      this.props.started(manager)
    })
  }

  componentDidMount() {
    if (
      this.props.currentUser &&
      !this.props.webchat.pending &&
      !this.props.webchat.started
    )
      this.setupChat()
  }

  componentDidUpdate() {
    if (
      this.props.currentUser &&
      !this.props.webchat.pending &&
      !this.props.webchat.started
    )
      this.setupChat()
  }

  render() {
    const { manager } = this.props.webchat
    if (manager) {
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      )
    }

    return null
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.user,
  customer: state.customer.customer,
  webchat: state.webchat,
})

const mapDispatchToProps = (dispatch) => ({
  init: bindActionCreators(Actions.startWebChat, dispatch),
  started: bindActionCreators(Actions.startedWebChat, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(WebChat)
