const baseUrl = process.env.REACT_APP_BASE_URL
const allSessionsUrl = baseUrl + '/api/TwilioSessions/'
const conversationsUrl = baseUrl + '/api/TwilioConversations/'
const tasksUrl = baseUrl + '/api/TwilioTask/'
const proxysUrl = baseUrl + '/api/TwilioProxy/'

class TwilioSessionService {
  _getHeaders = (token) => {
    return {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  getAllSessions = async (token) => {
    console.log('fetching all sessions')
    const headers = this._getHeaders(token)
    const response = await fetch(allSessionsUrl, {
      headers,
    })
    let sessions = await response.json()
    console.log('found all sessions', sessions)
    return sessions
  }

  refreshSessions = async (token) => {
    this.setState({ loading: true, hasLoaded: false })
    console.log('refreshing all sessions')
    const headers = this._getHeaders(token)
    const response = await fetch(allSessionsUrl, {
      headers,
    })
    let sessions = await response.json()
    console.log('found all sessions', sessions)
    return sessions
  }

  getProxySessions = async (token) => {
    const headers = this._getHeaders(token)
    const response = await fetch(baseUrl + '/api/TwilioProxy/', {
      headers,
    })
    let proxySessions = await response.json()
    console.log('found proxy sessions', proxySessions)
    return proxySessions
  }

  getConversations = async (token) => {
    const headers = this._getHeaders(token)
    const response = await fetch(conversationsUrl, {
      headers,
    })
    let conversations = await response.json()
    console.log('found conversations', conversations)
    return conversations
  }

  getTasks = async (token) => {
    const response = await fetch(baseUrl + '/api/TwilioTask/', {
      headers: this._getHeaders(token),
    })
    let tasks = await response.json()
    console.log('found tasks', tasks)
    return tasks
  }

  deleteProxySession = async (token, sess) => {
    console.log('deleting proxy:', sess.sid)
    const headers = this._getHeaders(token)
    const response = await fetch(proxysUrl + sess.sid, {
      headers,
      method: 'delete',
    })
    console.log(response.status)
  }

  deleteConversation = async (token, conversation) => {
    console.log('deleting conversation:', conversation.sid)
    const headers = this._getHeaders(token)
    const response = await fetch(conversationsUrl + conversation.sid, {
      headers,
      method: 'delete',
    })
    console.log(response.status)
  }

  deleteTask = async (token, task) => {
    console.log('deleting tasks:', task.sid)
    const headers = this._getHeaders(token)
    const response = await fetch(tasksUrl + task.sid, {
      headers,
      method: 'delete',
    })
    console.log(response.status)
  }
}

const twilioSessionService = new TwilioSessionService()

export default twilioSessionService
