const baseUrl = process.env.REACT_APP_BASE_URL
const getUrl = baseUrl + '/api/Register?status=NEW'
const updateUrl = baseUrl + '/api/Register'

class RegistrationService {
  _getHeaders = (token) => {
    return {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  getRegistrations = (token) => {
    return fetch(getUrl, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      credentials: 'include',
      method: 'GET',
    })
  }

  updateRegistration = (token, reg) => {
    const headers = this._getHeaders(token)
    return fetch(updateUrl, {
      method: 'PUT',
      body: JSON.stringify(reg),
      headers,
    })
  }
}

const registrationService = new RegistrationService()

export default registrationService
