// Production demo options
const demoOptions = [
  {
    label: 'Owl Shoes',
    value: 'owlshoes',
  },
  {
    label: 'Owl Air',
    value: 'owlair',
  },
  {
    label: 'Owl Bank',
    value: 'owlbank',
  },
  {
    label: 'Owl Health',
    value: 'owlhealth',
  },
  {
    label: 'Public Sector',
    value: 'pubsec',
  },
  {
    label: 'Zendesk',
    value: 'zendesk',
  },
  {
    label: 'Twilio CCAI',
    value: 'ccai',
  },
  {
    label: 'IVR Demo',
    value: 'ivr',
  },
  {
    label: 'Dialogflow ES Virtual Agent',
    value: 'dialogflow-ivr',
  },
  {
    label: 'Direct to Flex',
    value: 'directToFlex',
  },
]

// Production CRM Options
const crmOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Salesforce',
    value: 'sfdc',
  },
  {
    label: 'Zendesk',
    value: 'zd',
  },
  {
    label: 'HubSpot',
    value: 'hb',
  },
]

// Production Transcription Options
const transcriptionOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Google Speech-To-Text',
    value: 'google',
  },
  {
    label: 'Gridspace',
    value: 'gridspace',
  },
]

const prod = {
  /* To make a config prod-ready, add to the associated object above*/
  demoOptions: [...demoOptions],
  crmOptions: [...crmOptions],
  transcriptionOptions: [...transcriptionOptions],
  outboundOptions: [
    {
      label: 'Australia (+61488807639)',
      value: '+61488807639',
    },
    {
      label: 'Brazil (+5521933007003)',
      value: '+5521933007003',
    },
    {
      label: 'Germany (+4915735982122)',
      value: '+4915735982122',
    },
    {
      label: 'Hong Kong (+85230087278)',
      value: '+85230087278',
    },
    {
      label: 'Indonesia (+622180600645)',
      value: '+622180600645',
    },
    {
      label: 'Japan (+815031989715)',
      value: '+815031989715',
    },
    {
      label: 'France (+33757918695)',
      value: '+33757918695',
    },
    {
      label: 'Singapore (+6531292773)',
      value: '+6531292773',
    },
    {
      label: 'United Kingdom (+447449473539)',
      value: '+447449473539',
    },
    {
      label: 'United States (+17818083539)',
      value: '+17818083539',
    },
    {
      label: 'United States Toll Free (+18554638695)',
      value: '+18554638695',
    },
  ],
  outboundSmsOptions: [
    {
      label: 'Australia (+61488807639)',
      value: '+61488807639',
    },
    {
      label: 'Brazil (+5521933007003)',
      value: '+5521933007003',
    },
    {
      label: 'Germany (+4915735982122)',
      value: '+4915735982122',
    },
    {
      label: 'Japan (+815031989715)',
      value: '+815031989715',
    },
    {
      label: 'France (+33757918695)',
      value: '+33757918695',
    },
    {
      label: 'Singapore (+6582410193)',
      value: '+6582410193',
    },
    {
      label: 'United Kingdom (+447449473539)',
      value: '+447449473539',
    },
    {
      label: 'United States (+17818083539)',
      value: '+17818083539',
    },
    {
      label: 'United States Toll Free (+18554638695)',
      value: '+18554638695',
    },
  ],
}

const dev = {
  /* Add any dev-only configs to the appropriate field below */
  demoOptions: [...demoOptions],
  crmOptions: [...crmOptions],
  transcriptionOptions: [...transcriptionOptions],
  outboundOptions: [
    {
      label: 'United States (+13175160763)',
      value: '+13175160763',
    },
    {
      label: 'United States (+15083487005)',
      value: '+15083487005',
    },
    {
      label: 'United States (+13172038050)',
      value: '+13172038050',
    },
    {
      label: 'United Kingdom (+441173257399)',
      value: '+441173257399',
    },
  ],
  outboundSmsOptions: [
    {
      label: 'United States (+13175160763)',
      value: '+13175160763',
    },
    {
      label: 'United States (+15083487005)',
      value: '+15083487005',
    },
    {
      label: 'United States (+13172038050)',
      value: '+13172038050',
    },
    {
      label: 'United Kingdom (+441173257399)',
      value: '+441173257399',
    },
  ],
}

const envConfig = process.env.REACT_APP_ENV === 'production' ? prod : dev

const config = {
  /* Add common config values here */
  countries: [
    'United States',
    'Afghanistan',
    'Ãland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    "Cote d'Ivoire",
    'Croatia',
    'Cuba',
    'CuraÃ§ao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands (Malvinas)',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (Vatican City State)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'RÃ©union',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'Saint Barthelemy',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States Minor Outlying Islands',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Viet Nam',
    'Virgin Islands',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ],
  accountTypes: [
    {
      label: 'Employee',
      value: 'employee',
    },
    {
      label: 'Partner',
      value: 'partner',
    },
  ],
  jobRoles: [
    'Sales Engineering',
    'Training/Education',
    'Developer Evangelist',
    'Product Management',
    'Product Marketing',
    'Professional Services',
    'Research and Development',
    'Account Executive',
  ],
  navConfig: {
    demoSettings: {
      path: '/settings',
      label: 'Demo Settings',
      allow: ['admin', 'partnerAdmin', 'solutionsengineer'],
    },
    customerRecord: {
      path: '/customer',
      label: 'Customer Record',
      allow: [
        'admin',
        'accountexecutive',
        'partnerAdmin',
        'solutionsengineer',
        'partner',
      ],
    },
    sessionManagement: {
      path: '/sessions',
      label: 'Session Management',
      allow: ['admin', 'partnerAdmin', 'solutionsengineer'],
    },
    verifyFactors: {
      path: '/factors',
      label: 'Varify Factors',
      allow: ['admin', 'partnerAdmin', 'solutionsengineer'],
    },
    registrations: {
      path: '/registrations',
      label: 'Registrations',
      allow: ['admin', 'partnerAdmin'],
    },
    users: {
      path: '/admin',
      label: 'Users',
      allow: ['admin', 'partnerAdmin'],
    },
    seggestionForm: {
      path: '/ideas',
      label: 'Suggestion Form',
      allow: ['admin', 'partnerAdmin'],
    },
    optInUser: {
      path: '/optIn',
      label: 'Customer Opt-In',
      allow: [
        'admin',
        'partnerAdmin',
        'accountexecutive',
        'solutionsengineer',
        'partner',
      ],
    },
    verify: {
      path: '/verify',
      label: 'Verify',
      allow: [
        'admin',
        'partnerAdmin',
        'accountexecutive',
        'solutionsengineer',
        'partner',
      ],
    },
    messaging: {
      path: '/messaging',
      label: 'Messaging',
      allow: [
        'admin',
        'partnerAdmin',
        'accountexecutive',
        'solutionsengineer',
        'partner',
      ],
    },
  },
  ...envConfig,
}

export default config
