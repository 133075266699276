import {
  actionDefaults as optInActionDefaults,
  labelDefaults as optInLabelDefaults,
  optInName,
  optInReducer,
  optInSagas,
} from '@demoeng/usecase-customer-opt-in'

import {
  verifyReducer,
  verifyName,
  actionDefaults as verifyActionDefaults,
  labelDefaults as verifyDefaults,
  watchCheckVerify,
  watchStartVerify,
} from '@demoeng/usecase-verify'

import {
  messageReducer,
  watchMessage,
  messageName,
  actionDefaults as messageActionDefaults,
  labelDefaults as messageLabelDefaults,
} from '@demoeng/usecase-messaging'

import { coreSagas, defaultReducers } from '../store/slices'

export const useCaseReducers = {
  ...defaultReducers,
  [optInName]: optInReducer,
  [verifyName]: verifyReducer,
  [messageName]: messageReducer,
}

export const useCaseSagas = [
  ...coreSagas,
  ...optInSagas,
  watchCheckVerify,
  watchStartVerify,
  watchMessage,
]

export const useCaseLabels = [
  ...optInLabelDefaults.labels,
  ...verifyDefaults.labels,
  ...messageLabelDefaults.labels,
]

export const useCaseActionDefaults = [
  ...optInActionDefaults.actions,
  ...verifyActionDefaults.actions,
  ...messageActionDefaults.actions,
]
