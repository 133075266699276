import customerService from '../services/customerService'

const FETCH_CUSTOMER_PENDING = 'FETCH_CUSTOMER_PENDING'
const FETCH_CUSTOMER = 'FETCH_CUSTOMER'
const FETCH_CUSTOMER_FULFILLED = 'FETCH_CUSTOMER_FULFILLED'

const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
const UPDATE_CUSTOMER_PENDING = 'UPDATE_CUSTOMER_PENDING'
const UPDATE_CUSTOMER_FULFILLED = 'UPDATE_CUSTOMER_FULFILLED'

const UPDATE_CUSTOMER_DEMOCONFIG = 'UPDATE_CUSTOMER_DEMOCONFIG'

const initialState = {
  customer: null,
  loading: false,
  hasErrored: false,
  hasSubmitted: false,
  errorMessage: null,
  isUpdating: false,
}

export const customerFetchData = (authToken, email) => ({
  type: FETCH_CUSTOMER,
  payload: customerService
    .getCustomer(authToken, email)
    .then((customer) => {
      //TODO handle this better
      if (
        customer.status &&
        customer.status !== 200 &&
        customer.status !== 'NEW'
      ) {
        return {
          loading: false,
          hasError: true,
          errorMessage: customer.body,
          customer: null,
        }
      }
      return {
        loading: false,
        hasError: false,
        errorMessage: null,
        hasSubmitted: false,
        customer,
      }
    })
    .catch(() => ({
      loading: false,
      hasError: true,
      hasSubmitted: false,
      errorMessage: 'Unknown Error',
      customer: null,
    })),
})

export const updateCustomerData = (authToken, customer) => ({
  type: UPDATE_CUSTOMER,
  payload: customerService
    .updateCustomer(authToken, customer)
    .then(async (customer) => {
      console.log(UPDATE_CUSTOMER, customer)
      return {
        loading: false,
        hasError: false,
        hasLoaded: true,
        errorMessage: null,
        hasSubmitted: true,
        isUpdating: false,
        customer,
      }
    })
    .catch((err) => {
      return {
        loading: false,
        hasError: true,
        hasLoaded: true,
        errorMessage: 'Unknown error. Please reach out to #help-owldemo ',
        isUpdating: false,
        customer,
      }
    }),
})

export const updateCustomerDemoConfig = (customer) => ({
  type: UPDATE_CUSTOMER_DEMOCONFIG,
  payload: () => {
    return {
      loading: false,
      hasError: false,
      hasLoaded: true,
      errorMessage: null,
      customer,
    }
  },
})

export function reduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true,
        hasError: false,
        errorMessage: null,
      }
    case FETCH_CUSTOMER_FULFILLED:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_CUSTOMER_PENDING:
      return {
        ...state,
        hasError: false,
        errorMessage: null,
        isUpdating: true,
      }

    case UPDATE_CUSTOMER_FULFILLED:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_CUSTOMER_DEMOCONFIG:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
