import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginCallback, SecureRoute } from '@okta/okta-react'

import Home from './components/Home'
import NavComp from './components/Layout/Nav'
import SuggestionForm from './components/SuggestionForm'
import Settings from './components/DemoSettings/Settings'
import Login from './components/Login'
import Register from './components/Registrations/Register'
import Registration from './components/Registrations/Registrations'
import Users from './components/Users/UserContainer'
import Customer from './components/Customers/Customer'
import SessionManagement from './components/SessionManagement/SessionManagement'
import VerifyFactors from './components/VerifyFactors/VerifyFactors'
import WebChat from './components/WebChat'
import Analytics from './components/Analytics'
import { OptInPage } from '@demoeng/usecase-customer-opt-in'
import {
  Home as MessagingPage,
  Chat as ChatPage,
} from '@demoeng/usecase-messaging'
import { useAuthAdapter } from './hooks/useAuthAdapter'
import VerifyPage from './components/VerifyPage'

function Routing() {
  useAuthAdapter()

  return (
    <>
      <NavComp />
      <Switch>
        <Route exact path={`/`} component={Login} />
        <Route
          exact
          path={`/register`}
          component={(props) => <Register {...props} />}
        />
        <Route path={`/implicit/callback`} component={LoginCallback} />
        <SecureRoute exact path={`/home`} component={Home} />
        <SecureRoute exact path={`/ideas`} component={SuggestionForm} />
        <SecureRoute exact path={`/settings`} component={Settings} />
        <SecureRoute
          exact
          path={`/Registrations`}
          component={(props) => <Registration {...props} />}
        />
        <SecureRoute exact path={`/Customer`} component={Customer} />
        <SecureRoute exact path={`/Sessions`} component={SessionManagement} />
        <SecureRoute exact path={`/factors`} component={VerifyFactors} />
        <SecureRoute path={`/admin`} component={Users} />
        <SecureRoute path={`/optIn`} component={OptInPage} />
        <SecureRoute path={`/verify`} component={VerifyPage} />
        <SecureRoute path={`/messaging`} exact component={MessagingPage} />
        <SecureRoute path={`/messaging/chat`} exact component={ChatPage} />
      </Switch>
      <WebChat />
      <Analytics />
    </>
  )
}

export default Routing
