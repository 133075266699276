import React from 'react'
import { connect } from 'react-redux'
import { withOktaAuth } from '@okta/okta-react'
import { Container, Row, Table, Button, Spinner } from 'reactstrap'
import { MdModeEdit } from 'react-icons/md'

import registrationService from '../../services/registrationService'
import RegistrationModal from './RegistrationModal'

class Registrations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      registrations: [],
      registrationOpen: false,
      selectedRegistration: null,
      isLoading: true,
    }
  }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  componentDidMount() {
    this.fetchRegistrations()
  }

  approveRegistration = async (reg) => {
    console.log('approving', reg)
    reg.status = 'APPROVE'
    const token = this._getToken()
    const updateReg = await registrationService.updateRegistration(token, reg)
    console.log('registration approved', updateReg)
    this.fetchRegistrations()
  }

  denyRegistration = async (reg) => {
    console.log('denying', reg)
    reg.status = 'DENY'
    const token = this._getToken()
    const updateReg = await registrationService.updateRegistration(token, reg)
    console.log('registration denied', updateReg)
    this.fetchRegistrations()
  }

  editRegistration = async (reg) => {
    console.log('editting', reg)
    //add edit form modal
    this.setState({ registrationOpen: true, selectedRegistration: reg })
  }

  toggleModal = () => {
    if (this.state.registrationOpen) this.fetchRegistrations()
    this.setState({ registrationOpen: false })
  }

  fetchRegistrations = async () => {
    const token = this._getToken()
    let results = await registrationService.getRegistrations(token)

    results = await results.json()
    console.log(results)
    this.setState({
      registrations: results,
      isLoading: false,
    })
  }

  createTable(rows, isPartner) {
    return (
      <Table striped responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.isLoading ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                <Spinner
                  size="lg"
                  color="secondary"
                  style={{ margin: 'auto' }}
                />
              </td>
            </tr>
          ) : rows && rows.length > 0 ? (
            rows.map((reg) => {
              if (reg.partner === !isPartner) return null
              return (
                <tr key={reg.id}>
                  <td>{reg.id}</td>
                  <td>{reg.firstName}</td>
                  <td>{reg.lastName}</td>
                  <td>{reg.email}</td>
                  <td>{reg.status}</td>
                  <td>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.approveRegistration(reg)
                      }}
                    >
                      Approve
                    </Button>{' '}
                    <Button
                      color="danger"
                      onClick={() => {
                        this.denyRegistration(reg)
                      }}
                    >
                      Deny
                    </Button>{' '}
                    <Button
                      color="warning"
                      onClick={() => {
                        this.editRegistration(reg)
                      }}
                    >
                      <MdModeEdit />
                    </Button>
                  </td>
                </tr>
              )
            })
          ) : null}
        </tbody>
      </Table>
    )
  }

  render() {
    const { currentUser, admin, partnerAdmin } = this.props
    const { registrationOpen, selectedRegistration, registrations } = this.state

    if (!currentUser) {
      return (
        <Container>
          <Spinner size="lg" color="secondary" style={{ margin: 'auto' }} />
        </Container>
      )
    }

    return (
      <Container>
        {admin ? (
          <React.Fragment>
            <Row>
              <h1>Registrations</h1>
            </Row>
            <Row>{this.createTable(registrations, false)}</Row>
          </React.Fragment>
        ) : null}
        {partnerAdmin ? (
          <React.Fragment>
            <Row>
              <h1>Partner Registrations</h1>
            </Row>
            <Row>{this.createTable(registrations, true)}</Row>
          </React.Fragment>
        ) : null}
        <RegistrationModal
          registration={selectedRegistration}
          registrationOpen={registrationOpen}
          registrationToggle={this.toggleModal}
          token={this._getToken()}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.user,
  admin: state.currentUser.user?.admin,
  partnerAdmin: state.currentUser.user?.partnerAdmin,
})

export default withOktaAuth(connect(mapStateToProps, null)(Registrations))
