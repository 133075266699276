import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withOktaAuth } from '@okta/okta-react'
import { Container, Row, Spinner } from 'reactstrap'
import twilioSessionService from '../../services/twilioSessionService'

import TaskTable from './TaskTable'
import ProxyTable from './ProxyTable'
import ConversationTable from './ConversationTable'

class SessionManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      hasError: false,
      errorMessage: null,
      hasLoaded: false,
      proxyLoading: false,
      tasksLoading: false,
      conversationsLoading: false,
    }
    this.getAllSessions = this.getAllSessions.bind(this)
    this.refreshSessions = this.refreshSessions.bind(this)
    this.deleteProxySession = this.deleteProxySession.bind(this)
    this.deleteConversation = this.deleteConversation.bind(this)
    this.deleteTask = this.deleteTask.bind(this)
    this.refreshProxy = this.refreshProxy.bind(this)
    this.refreshTasks = this.refreshTasks.bind(this)
    this.refreshConversations = this.refreshConversations.bind(this)
  }

  componentDidMount() {
    this.getAllSessions()
  }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  async getAllSessions() {
    const authToken = this._getToken()
    const sessions = await twilioSessionService.getAllSessions(authToken)

    this.setState({ ...sessions, loading: false, hasLoaded: true })
  }

  async refreshSessions() {
    this.setState({ loading: true, hasLoaded: false })
    const authToken = this._getToken()
    const sessions = await twilioSessionService.getAllSessions(authToken)
    this.setState({ ...sessions, loading: false, hasLoaded: true })
  }

  async refreshProxy() {
    this.setState({ proxyLoading: true })
    const authToken = this._getToken()
    const proxySessions = await twilioSessionService.getProxySessions(authToken)
    this.setState({ proxySessions, proxyLoading: false })
  }

  async refreshTasks() {
    this.setState({ tasksLoading: true })
    const authToken = this._getToken()
    const tasks = await twilioSessionService.getTasks(authToken)
    this.setState({ tasks, tasksLoading: false })
  }

  async refreshConversations() {
    this.setState({ conversationsLoading: true })
    const authToken = this._getToken()
    const conversations = await twilioSessionService.getConversations(authToken)
    this.setState({ conversations, conversationsLoading: false })
  }

  async deleteProxySession(sess) {
    let proxySessions = this.state.proxySessions
    this.setIsDeleting(proxySessions, sess)
    this.setState({ proxySessions })
    const authToken = this._getToken()
    await twilioSessionService.deleteProxySession(authToken, sess)
    this.removeItem(proxySessions, sess)
    this.setState({ proxySessions })
  }

  async deleteConversation(conversation) {
    let conversations = this.state.conversations
    this.setIsDeleting(conversations, conversation)
    this.setState({ conversations })
    const authToken = this._getToken()
    await twilioSessionService.deleteConversation(authToken, conversation)

    this.removeItem(conversations, conversation)
    this.setState({ conversations })
  }

  async deleteTask(task) {
    let tasks = this.state.tasks
    this.setIsDeleting(tasks, task)
    this.setState({ tasks })
    const authToken = this._getToken()
    await twilioSessionService.deleteTask(authToken, task)

    this.removeItem(tasks, task)
    this.setState({ tasks })
  }

  setIsDeleting(items, item) {
    let matchingIndex = items.findIndex((obj) => obj.sid === item.sid)
    items[matchingIndex].isDeleting = true
  }

  removeItem(items, item) {
    let matchingIndex = items.findIndex((obj) => obj.sid === item.sid)
    delete items[matchingIndex]
  }

  render() {
    const {
      proxySessions,
      proxyLoading,
      tasks,
      tasksLoading,
      conversations,
      conversationsLoading,
    } = this.state
    return (
      <Container>
        <Row>
          <h1>Current Sessions</h1>
        </Row>
        {this.state.loading && (
          <Row>
            <Spinner size="lg" color="secondary" />
          </Row>
        )}
        {this.state.hasLoaded && !this.state.hasError ? (
          <div>
            <ProxyTable
              proxySessions={proxySessions}
              proxyLoading={proxyLoading}
              refreshProxy={this.refreshProxy}
              deleteProxySession={this.deleteProxySession}
            />
            <TaskTable
              tasks={tasks}
              tasksLoading={tasksLoading}
              refreshTasks={this.refreshTasks}
              deleteTask={this.deleteTask}
            />
            <ConversationTable
              conversations={conversations}
              conversationLoading={conversationsLoading}
              refreshConversations={this.refreshConversations}
              deleteConversation={this.deleteConversation}
            />
          </div>
        ) : null}

        {this.state.hasError ? (
          <React.Fragment>
            <h3>Error loading record</h3>
            <p>{this.state.errorMessage}</p>
          </React.Fragment>
        ) : null}
        <Row></Row>
        <br />
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer.customer,
})

const mapDispatchToProps = (dispatch) => ({})

export default withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(SessionManagement),
)
