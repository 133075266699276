const baseUrl = process.env.REACT_APP_BASE_URL
const fetchUrl = baseUrl + '/api/Customer?email='
const updateUrl = baseUrl + '/api/Customer/'

class CustomerService {
  _getHeaders = (token) => {
    return {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  getCustomer = (token, email) => {
    const headers = this._getHeaders(token)
    return fetch(fetchUrl + encodeURIComponent(email.replace('+flex', '')), {
      headers,
    })
      .then((config) => {
        if (config.status !== 200) {
          return config
        }
        return config.json()
      })
      .then((customer) => {
        if (customer.status !== 200 && customer.status !== 'NEW') {
          return customer
        }
        return this.unpackIds(customer)
      })
  }

  updateCustomer = (token, customer) => {
    const headers = this._getHeaders(token)
    return fetch(updateUrl + customer.id, {
      method: 'PUT',
      body: JSON.stringify(customer),
      headers,
    })
      .then(async (currCust) => {
        return currCust.json()
      })
      .then((customer) => {
        return this.unpackIds(customer)
      })
  }

  unpackIds = (customer) => {
    customer.phones = customer.customerIds.filter(
      (item) => item.recordType === 'phone',
    )
    customer.emails = customer.customerIds.filter(
      (item) => item.recordType === 'email',
    )
    customer.socials = customer.customerIds.filter(
      (item) => item.recordType !== 'phone' && item.recordType !== 'email',
    )

    customer.customerIds = customer.customerIds.map((item) => {
      if (item.recordType === 'email' && item.id === customer.primaryEmail) {
        item.isPrimary = true
      } else if (
        item.recordType === 'phone' &&
        item.id === customer.primaryPhone
      ) {
        item.isPrimary = true
      } else {
        item.isPrimary = false
      }
      return item
    })
    return customer
  }
}

const customerService = new CustomerService()

export default customerService
