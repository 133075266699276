import React from 'react'
import { Container, Row, Col, List } from 'reactstrap'

function Home() {
  return (
    <Container fluid="sm">
      <Row className="m-5">
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <h1 className="display-4 mt-4">
            Welcome to the <br /> Owl Demo Platform
          </h1>
          <p className="lead">
            Use this internal tool to configure your Twilio demos.
          </p>
          <hr className="mb-5" />
          <p className="lead">Contact DemoEng:</p>
          <List type="unstyled" className="lead">
            <li className="p-2">
              <a href="mailto:se-demo-engineering@twilio.com">
                se-demo-engineering@twilio.com
              </a>
            </li>
            <li className="p-2">
              <code>#help-owldemo</code> in Slack
            </li>
          </List>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
