import React from 'react'
import Moment from 'react-moment'
import { Spinner, Button } from 'reactstrap'

const ConversationRow = (props) => {
  const { conversation, onDelete } = props
  return (
    <tr>
      <td>
        <Moment
          format="YYYY-MM-DD HH:mm"
          date={conversation.created_at}
        ></Moment>
      </td>
      <td>
        <Moment date={conversation.created_at} durationFromNow />
      </td>
      <td>{conversation.friendlyName}</td>
      <td>{conversation.friendlyParticipants}</td>
      <td>
        {conversation.canDelete && (
          <Button
            color="danger"
            onClick={() => {
              onDelete(conversation)
            }}
            disabled={conversation.isDeleting}
          >
            Disconnect
          </Button>
        )}
        {conversation.isDeleting && <Spinner size="sm" color="secondary" />}
      </td>
    </tr>
  )
}

export default ConversationRow
