import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import Segment from '@segment/analytics.js-core/build/analytics'
import SegmentIntegration from '@segment/analytics.js-integration-segmentio'

const Analytics = ({ customer }) => {
  const [analytics, setAnalytics] = useState(null)

  const location = useLocation()

  if (process.env.REACT_APP_GA) ReactGA.initialize(process.env.REACT_APP_GA)

  useEffect(() => {
    if (analytics) analytics.page()
  }, [location, location.pathname, analytics])

  useEffect(() => {
    if (!process.env.REACT_APP_SEGMENT_KEY || !customer?.id) return

    if (!analytics) {
      const segment = new Segment()
      segment.use(SegmentIntegration)
      segment.initialize({
        'Segment.io': {
          apiKey: process.env.REACT_APP_SEGMENT_KEY,
        },
      })

      setAnalytics(segment)
      window.analytics = segment
    }
  }, [customer, analytics])

  /**
   * This component is not intended to render anything
   * It's used only to setup analytics and track page changes
   */
  return null
}

const mapStateToProps = (state) => ({
  customer: state.customer.customer,
  user: state.users.selectedUser,
  //may want to use okta or partner status in future
  currentUser: state.currentUser.user,
  partner: state.currentUser.currentUser?.partner,
})

export default connect(mapStateToProps, null)(Analytics)
