import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { SyncProvider } from '@demoeng/usecase-customer-opt-in'

import store from './store'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './css/default.css'
import './css/overwrite.css'
import './css/style.css'
import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <SyncProvider>
      <App />
    </SyncProvider>
  </Provider>,
  document.getElementById('root'),
)
