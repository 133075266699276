import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withOktaAuth } from '@okta/okta-react'
import { withRouter } from 'react-router-dom'
import { Container, Col, Row, Spinner, Button } from 'reactstrap'
import { MdArrowBack } from 'react-icons/md'

import * as demoConfigActions from '../../states/demoConfig'
import * as customerActions from '../../states/customer'

import SettingsForm from './SettingsForm'

class Settings extends Component {
  state = { partner: true }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  componentDidMount() {
    if (
      this.props.currentUser &&
      !this.props.customer &&
      !this.props.customerLoading
    ) {
      this.getCustomer()
    } else if (
      this.props.isAdmin &&
      this.props.currentUser &&
      this.props.customer &&
      this.props.customer.id !== this.props.selectedUser.id
    ) {
      this.getCustomer()
    } else if (this.props.currentUser && this.props.customer) {
      this.getDemoConfigs()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentUser &&
      !this.props.customer &&
      !this.props.customerLoading &&
      !prevProps.customer
    ) {
      this.getCustomer()
    } else if (
      this.props.isAdmin &&
      this.props.currentUser &&
      this.props.customer &&
      !this.props.customerLoading &&
      this.props.customer.id !== this.props.selectedUser.id
    ) {
      this.getCustomer()
    } else if (
      this.props.currentUser &&
      this.props.customer &&
      !prevProps.customer &&
      !this.props.demoConfig &&
      !this.props.demoConfigLoading
    ) {
      this.getDemoConfigs()
    } else if (
      this.props.customer &&
      prevProps.customer &&
      this.props.customer.id !== prevProps.customer.id
    ) {
      this.getDemoConfigs()
    }
  }

  getCustomer = async () => {
    const token = await this._getToken()
    if (this.props.isAdmin) {
      this.props.fetchCustomer(token, this.props.selectedUser.email)
    } else {
      this.props.fetchCustomer(token, this.props.currentUser.email)
    }
  }

  getDemoConfigs = async () => {
    const token = await this._getToken()
    this.props.getDemoConfigs(token, this.props.customer)
  }

  onUpdate = async (state) => {
    const token = await this._getToken()
    const config = {
      activeDemo: state.activeDemo,
      activeCrm: state.activeCrm,
      activeTranscription: state.activeTranscription,
      preferredOutbound: state.preferredOutbound,
      preferredSmsOutbound: state.preferredSmsOutbound,
      id: this.props.customer.id,
      currentDemoCustomization: state.currentDemoCustomization,
    }
    this.props.updateDemoConfig(token, this.props.customer, config)
  }

  navigateBackToAdmin = () => {
    this.props.history.push('/admin')
  }

  render() {
    const {
      demoConfig,
      demoConfigLoading,
      customerLoading,
      isAdmin,
      selectedUser,
    } = this.props

    return (
      <Container>
        <Row>
          <Col xs="12">
            <h2>
              {isAdmin ? (
                <Button
                  color="secondary"
                  onClick={this.navigateBackToAdmin}
                  style={{ marginRight: 6 }}
                >
                  <MdArrowBack />
                </Button>
              ) : null}
              Current demonstration settings
              {isAdmin ? (
                <span>
                  {' '}
                  - {selectedUser?.firstName} {selectedUser?.lastName}
                </span>
              ) : null}
            </h2>
            <small>
              These settings are used to customize the configuration of the
              various owl demo websites.
            </small>
          </Col>
        </Row>
        {demoConfigLoading || customerLoading ? (
          <Row>
            <Spinner size="lg" color="secondary" />
          </Row>
        ) : demoConfig ? (
          <SettingsForm
            demoConfig={demoConfig}
            onUpdate={this.onUpdate}
            isUpdating={this.props.isUpdating}
            hasError={this.props.hasError}
            errorMessage={this.props.errorMessage}
            hasSubmitted={this.props.hasSubmitted}
            partner={this.props.partner}
            admin={this.props.admin}
          />
        ) : null}
        <br />
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.user,
  customer: state.customer.customer,
  demoConfig: state.demoConfig.demoConfig,
  selectedUser: state.users.selectedUser,
  isUpdating: state.demoConfig.isUpdating,
  hasError: state.demoConfig.hasError,
  errorMessage: state.demoConfig.errorMessage,
  hasSubmitted: state.demoConfig.hasSubmitted,
  customerLoading: state.customer.loading,
  demoConfigLoading: state.demoConfig.loading,
  partner: state.currentUser.user?.partner,
  admin: state.currentUser.user?.admin,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCustomer: bindActionCreators(
    customerActions.customerFetchData,
    dispatch,
  ),
  getDemoConfigs: bindActionCreators(
    demoConfigActions.getDemoConfigs,
    dispatch,
  ),
  updateDemoConfig: bindActionCreators(
    demoConfigActions.updateDemoConfigData,
    dispatch,
  ),
})

export default withRouter(
  withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Settings)),
)
