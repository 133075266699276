import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import { owlDemoReducers } from '../states/index'
import {
  useCaseReducers,
  useCaseSagas,
  useCaseActionDefaults,
  useCaseLabels,
} from '../states/useCases.js'
import {
  actions,
  createStore as wrongCreatestore,
} from '@demoeng/website-customization'
import config from './config'

const labelDefaults = {
  labels: [
    {
      name: 'Brand Name',
      key: 'nav.brand.title',
      override: false,
      overrideText: '',
      default: '',
    },
  ],
}

console.log({ owlDemoReducers, useCaseReducers })

const reducers = combineReducers({
  ...owlDemoReducers,
  ...useCaseReducers,
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk, promise, sagaMiddleware),
    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : function (f) {
          return f
        },
  ),
)

useCaseSagas?.forEach((saga) => sagaMiddleware.run(saga))

store.dispatch(actions.setSiteConfig({ ...config }))
store.dispatch(
  actions.setDefaultCustomization({
    defaultCustomization: {
      segmentEnabled: false,
      segmentKey: '',
      language: 'en',
    },
  }),
)

const labels = [...labelDefaults.labels, ...useCaseLabels]
store.dispatch(actions.setDefaultLabels({ labels }))

const defaultActions = [...useCaseActionDefaults]
store.dispatch(actions.setDefaultActions({ actions: defaultActions }))

export default store
