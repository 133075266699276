import axios from 'axios'

const { REACT_APP_VERIFY_BACKEND_URL: BASE_URL } = process.env

class VerifyService {
  constructor() {
    this.client = axios.create({
      baseURL: BASE_URL,
      headers: { 'Content-Type': 'application/json' },
    })
  }

  getEntityId = async (identity, Token) => {
    try {
      const { data } = await this.client.post(`getEntityId`, {
        identity,
        Token,
      })
      return data
    } catch (err) {
      console.error(`Error fetching EntityId: ${err.message}`)
    }
  }

  getEntity = async (identity, Token) => {
    try {
      const { data } = await this.client.post(`getEntity`, {
        identity,
        Token,
      })
      return data
    } catch (err) {
      console.error(`Error fetching entity: ${err.message}`)
      return {
        entity: null,
      }
    }
  }

  getFactors = async (identity, Token) => {
    try {
      const {
        data: { factors },
      } = await this.client.post(`getFactors`, {
        identity,
        Token,
      })
      return {
        success: true,
        factors: factors,
      }
    } catch (err) {
      console.error(`Verify Service: Error fetching factors: ${err}`)
      return {
        success: false,
        factors: [],
      }
    }
  }

  deleteFactor = async (identity, Token, factorSid) => {
    try {
      const { data } = await this.client.post(`deleteFactor`, {
        identity,
        Token,
        factorSid,
      })
      return {
        success: true,
      }
    } catch (err) {
      console.error(`Verify Service: Error deleting factor: ${err}`)
      return {
        success: false,
      }
    }
  }

  createChallenge = async (identity, Token, factorSid, details) => {
    try {
      const { message, logoUrl } = details

      let detailsObj = {
        message: 'Sign-in attempt',
        fields: [
          {
            label: 'logo',
            value:
              'https://twilio-cms-prod.s3.amazonaws.com/images/twilio-mark-red.width-808.png',
          },
        ],
        hiddenDetails: {
          source: 'owl-home',
        },
      }

      if (message && message !== '') detailsObj.message = message
      if (logoUrl && logoUrl !== '') detailsObj.fields[0].value = logoUrl

      const { data } = await this.client.post(`startChallenge`, {
        channel: 'push',
        identity,
        Token,
        factorSid,
        details: detailsObj,
      })
      return data
    } catch (err) {
      console.error(`Verify Service: Error creating challenge: ${err}`)
      return {
        success: false,
      }
    }
  }

  createFactor = async (identity, Token, factorType, friendlyName) => {
    try {
      const { data } = await this.client.post(`createFactor`, {
        identity,
        Token,
        factorType,
        friendlyName,
      })
      return {
        success: true,
        factor: data,
      }
    } catch (err) {
      console.error(`Verify Service: Error creating factor: ${err}`)
      return {
        success: false,
        error: err,
      }
    }
  }

  verifyNewFactor = async (identity, Token, factorSid, code) => {
    try {
      const { data } = await this.client.post(`verifyNewFactor`, {
        identity,
        Token,
        factorSid,
        code,
      })

      if (data.status && data.status === 'unverified') {
        return {
          success: false,
          factor: data,
          error: { message: 'Invalid token.' },
        }
      }

      return {
        success: true,
        factor: data,
      }
    } catch (err) {
      console.error(`Verify Service: Error verifying new factor: ${err}`)
      return {
        success: false,
        error: err,
      }
    }
  }
}

const verifyService = new VerifyService()
export default verifyService
