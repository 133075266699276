const baseUrl = process.env.REACT_APP_BASE_URL
const fetchUrl = baseUrl + '/api/User'
const updateUrl = baseUrl + '/api/User/'

class UserService {
  _getHeaders = (token) => {
    return {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  getUsers = (token) => {
    const headers = this._getHeaders(token)
    return fetch(fetchUrl, {
      headers,
    }).then((config) => {
      if (config.status !== 200) {
        return config
      }
      return config.json()
    })
  }

  updateUser = (token, user) => {
    return fetch(updateUrl + user.id, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(async (user) => {
      return user.json()
    })
  }
}

const userService = new UserService()

export default userService
