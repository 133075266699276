import siteConfigReducer, { name as siteReducerName } from './siteConfig'
import authReducer, { name as authReducerName } from './auth'
import { watchCustomer } from './customer'
import themeReducer, {
  name as themeReducerName,
  watchTheme,
} from './themeOverride'
import { watchLabel } from './labelOverride'
import { watchActions } from './actionOverride'

export const defaultReducers = {
  [siteReducerName]: siteConfigReducer,
  [authReducerName]: authReducer,
  [themeReducerName]: themeReducer,
}

export const coreSagas = [watchCustomer, watchLabel, watchTheme, watchActions]
