import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import Register from './Register'

const RegistratonModal = (props) => {
  const {
    registration,
    registrationToggle: toggle,
    registrationOpen: isOpen,
    token,
  } = props

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={true}>
      <ModalHeader toggle={toggle}>Registration</ModalHeader>
      <ModalBody>
        <Register registration={registration} token={token} toggle={toggle} />
      </ModalBody>
    </Modal>
  )
}

export default RegistratonModal
