import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withOktaAuth } from '@okta/okta-react'
import { withRouter } from 'react-router-dom'

import { Container } from 'reactstrap'

import * as Actions from '../../states/users'
import UserList from './UserList'
import UserModal from './UserModal'
import Customer from '../Customers/Customer'
import Settings from '../DemoSettings/Settings'

class UserContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: 'list',
      editModelOpen: false,
    }
  }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  async componentDidMount() {
    const token = this._getToken()
    if (!this.props.users || this.props.users.length === 0) {
      this.props.fetchUsers(token)
    }

    const currentPath = this.props.history.location.pathname
    if (currentPath.indexOf('edit') > -1) {
      console.log('start at edit')
    } else if (currentPath.indexOf('customer') > -1) {
      console.log('start at customer')
    } else if (currentPath.indexOf('demoConfig') > -1) {
      console.log('start at demoConfig')
    }
  }

  async componentDidUpdate() {
    if (
      this.props.history.location.pathname === '/admin' &&
      this.state.mode !== 'list'
    ) {
      this.setState({ mode: 'list' })
    }
  }

  toggleModal = () => {
    if (this.state.editModelOpen && !this.props.loading) {
      const token = this._getToken()
      this.props.fetchUsers(token)
    }
    this.props.history.push('/admin')
    this.setState({ editModelOpen: false })
  }

  editUser = async (user) => {
    this.props.selectUser(user)
    this.props.history.push('/admin/' + user.id + '/edit')
    this.setState({ editModelOpen: true })
  }

  openCustomer = async (user) => {
    this.props.selectUser(user)
    this.props.history.push('/admin/' + user.id + '/customer')
    this.setState({ mode: 'customer' })
  }

  openDemoConfig = async (user) => {
    this.props.selectUser(user)
    this.props.history.push('/admin/' + user.id + '/demoConfig')
    this.setState({ mode: 'demoConfig' })
  }

  updateUserRecord = async (user) => {
    const token = this._getToken()
    this.props.updateUser(token, user)
  }

  render() {
    const { mode, editModelOpen } = this.state
    const {
      users,
      loading,
      selectedUser,
      errorMessage,
      hasSubmitted,
      isUpdating,
      admin,
      partnerAdmin,
    } = this.props

    if (!admin && !partnerAdmin) {
      return <Container>Unauthorized</Container>
    }

    return (
      <Container>
        {mode === 'list' ? (
          <UserList
            users={users}
            loading={loading}
            openCustomer={this.openCustomer}
            editUser={this.editUser}
            openDemoConfig={this.openDemoConfig}
            admin={admin}
            partnerAdmin={partnerAdmin}
          />
        ) : mode === 'customer' ? (
          <Customer isAdmin />
        ) : mode === 'demoConfig' ? (
          <Settings isAdmin />
        ) : null}
        <UserModal
          user={selectedUser}
          toggle={this.toggleModal}
          open={editModelOpen}
          updateUser={this.updateUserRecord}
          errorMessage={errorMessage}
          hasSubmitted={hasSubmitted}
          isUpdating={isUpdating}
        />
        <br />
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.loading,
  users: state.users.users,
  hasError: state.users.hasError,
  errorMessage: state.users.errorMessage,
  hasSubmitted: state.users.hasSubmitted,
  isUpdating: state.users.isUpdating,
  selectedUser: state.users.selectedUser,
  partnerAdmin: state.currentUser.user?.partnerAdmin,
  admin: state.currentUser.user?.admin,
})

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: bindActionCreators(Actions.fetchUsers, dispatch),
  updateUser: bindActionCreators(Actions.updateUser, dispatch),
  selectUser: bindActionCreators(Actions.selectUser, dispatch),
})

export default withRouter(
  withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(UserContainer)),
)
