import React from 'react'
import Moment from 'react-moment'
import { Spinner, Button } from 'reactstrap'

const ProxyRow = (props) => {
  const { session, onDelete } = props
  return (
    <tr>
      <td>
        <Moment format="YYYY-MM-DD HH:mm" date={session.created_at}></Moment>
      </td>
      <td>
        <Moment date={session.created_at} durationFromNow />
      </td>
      <td>{session.friendlyName}</td>
      <td>
        {session.canDelete && (
          <Button
            color="danger"
            onClick={() => {
              onDelete(session)
            }}
            disabled={session.isDeleting}
          >
            Disconnect
          </Button>
        )}
        {session.isDeleting && <Spinner size="sm" color="secondary" />}
      </td>
    </tr>
  )
}

export default ProxyRow
