import axios from 'axios'

function formatHeaders(authToken) {
  return {
    Authorization: 'Bearer ' + authToken,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
}

export function fetchCustomer(userApi, authToken, email) {
  const headers = formatHeaders(authToken)
  return axios
    .get(
      `${userApi}Customer?email=${encodeURIComponent(
        email.replace('+flex', '').replace('+demo', ''),
      )}`,
      {
        headers,
      },
    )
    .then((response) => response.data)
}

export function updateApi(demoConfig, authToken, userApi, currentDemo) {
  let url = `${userApi}DemoConfiguration/${demoConfig.id}`
  if (currentDemo) url += '/' + currentDemo
  const headers = formatHeaders(authToken)
  return axios
    .put(url, demoConfig, {
      headers,
    })
    .then((response) => response.data)
}

export function* fetchSpecificDemoConfig(
  customer,
  authToken,
  userApi,
  currentDemo,
) {
  const headers = formatHeaders(authToken)
  return axios
    .get(`${userApi}DemoConfiguration/${customer.id}/${currentDemo}`, {
      headers,
    })
    .then((response) => response.data)
}
