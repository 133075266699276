import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withOktaAuth } from '@okta/okta-react'
import { withRouter } from 'react-router-dom'
import { MdArrowBack } from 'react-icons/md'
import { Container, Row, Spinner, Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as Actions from '../../states/customer'

import CustomerForm from './CustomerForm'

class Customer extends Component {
  componentDidMount() {
    if (this.props.currentUser) this.getCustomer()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) this.getCustomer()
  }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  updateCustomer = (values) => {
    console.log('updateCustomer: ', values)
    let primaryPhone = values.phones[0].id
    values.phones.forEach((ph) => {
      if (ph.isPrimary) primaryPhone = ph.id
    })
    let primaryEmail = values.emails[0].id
    values.emails.forEach((em) => {
      if (em.isPrimary) primaryEmail = em.id
    })

    const customerIds = [...values.phones, ...values.emails, ...values.socials]
    let tempCustomer = { ...this.props.customer }
    delete tempCustomer.socials
    delete tempCustomer.emails
    delete tempCustomer.phones
    let tempValues = { ...values }
    delete tempValues.socials
    delete tempValues.emails
    delete tempValues.phones
    delete tempValues.demoConfig
    const customer = {
      ...tempCustomer,
      ...tempValues,
      name: `${values.firstName} ${values.lastName}`,
      primaryPhone,
      primaryEmail,
      customerIds,
    }

    this.props.updateCustomer(this._getToken(), customer)
    this.updateSegment(customer)
  }

  updateSegment = (customer) => {
    if (window.analytics) {
      console.log('calling identify: ', customer.id)
      window.analytics.identify(customer.id, {
        email: customer.primaryEmail,
        firstName: customer.firstName,
        lastName: customer.lastName,
        name: customer.name,
        phone: customer.primaryPhone,
      })
      const externalIds = customer.customerIds.map((record) => {
        if (record.recordType === 'phone' || record.recordType === 'email') {
          return {
            id: record.id,
            type: record.recordType,
            collection: 'users',
            encoding: 'none',
          }
        }
        return null
      })
      const segmentCustomer = { ...customer }
      delete segmentCustomer.demoConfig
      delete segmentCustomer.customerIds
      console.log('tracking profile update', segmentCustomer)
      console.log('with externalIds', externalIds)
      window.analytics.track(
        'Update Profile',
        {
          ...segmentCustomer,
        },
        { externalIds: externalIds.filter((el) => el !== null) },
      )
    }
  }

  getCustomer = async () => {
    let email
    if (this.props.isAdmin) {
      email = this.props.user?.email
    } else {
      email = this.props.currentUser?.email
    }
    console.log('fetching customer record', email)
    this.props.fetchCustomer(this._getToken(), email)
  }

  navigateBackToAdmin = () => {
    this.props.history.push('/admin')
  }

  render() {
    const { isAdmin } = this.props

    return (
      <Container>
        <Row>
          <h1>
            {isAdmin ? (
              <Button
                color="secondary"
                onClick={this.navigateBackToAdmin}
                style={{ marginRight: 6 }}
              >
                <MdArrowBack />
              </Button>
            ) : null}
            Customer Record ({this.props.customer?.id})
            {isAdmin ? (
              <React.Fragment>
                {' '}
                - {this.props.user.firstName} {this.props.user.lastName}
              </React.Fragment>
            ) : null}
          </h1>
        </Row>
        {this.props.loading ? (
          <Row>
            <Spinner size="lg" color="secondary" />
          </Row>
        ) : this.props.hasError ? (
          <React.Fragment>
            <h3>Error loading record</h3>
            <p>{this.props.errorMessage}</p>
          </React.Fragment>
        ) : this.props.customer ? (
          <CustomerForm
            customer={this.props.customer}
            baseUrl={this.props.baseUrl}
            auth={this.props.authService}
            updateCustomer={this.updateCustomer}
            errorMessage={this.props.errorMessage}
            hasSubmitted={this.props.hasSubmitted}
            isUpdating={this.props.isUpdating}
            partner={this.props.partner}
          />
        ) : null}
        <Row></Row>
        <br />
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.customer.loading,
  customer: state.customer.customer,
  hasError: state.customer.hasError,
  errorMessage: state.customer.errorMessage,
  hasSubmitted: state.customer.hasSubmitted,
  isUpdating: state.customer.isUpdating,
  user: state.users.selectedUser,
  currentUser: state.currentUser.user,
  partner: state.currentUser.currentUser?.partner,
})

const mapDispatchToProps = function (dispatch) {
  return {
    fetchCustomer: bindActionCreators(Actions.customerFetchData, dispatch),
    updateCustomer: bindActionCreators(Actions.updateCustomerData, dispatch),
  }
}

export default withRouter(
  withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Customer)),
)
