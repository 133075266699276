import React from 'react'

const SuggestionForm = () => {
  return (
    <iframe
      title="airtable-suggestion-form"
      src="https://airtable.com/embed/shrLVC7jDVSm0Spx9?backgroundColor=yellowLight"
      frameBorder="0"
      width="100%"
      height="800"
      style={{ background: 'transparent' }}
    ></iframe>
  )
}

export default SuggestionForm
