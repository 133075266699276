import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '@demoeng/website-customization'

export function useAuthAdapter() {
  const { authState, oktaAuth } = useOktaAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    setAuthentication().catch(console.error)
  }, [authState?.accessToken?.accessToken])

  async function setAuthentication() {
    const user = await oktaAuth.getUser()
    dispatch(
      actions.setAuth({
        accessToken: authState?.accessToken?.accessToken,
        idToken: authState?.idToken?.idToken,
        error: authState?.error,
        user: user,
        loading: false,
      }),
    )
  }
}

export function useSiteConfigAdapter() {}
