import React from 'react'
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import verifyService from '../../services/verifyService'

const createChallenge = async (identity, token, factorSid, details) => {
  const entityId = await verifyService.getEntityId(identity, token)
  const { challenge } = await verifyService.createChallenge(
    entityId,
    token,
    factorSid,
    details,
  )
  console.log(challenge)
}

const validationSchema = yup.object({
  logoUrl: yup
    .string('A Custom Logo URL (HTTPS links only)')
    .max(2000, 'URL cannot be longer than 2000 characters'),
  message: yup
    .string('A Custom Challenge Message')
    .max(100, 'Challenge Message cannot be more than 100 characters'),
})

export default function CreatePushChallengeForm(props) {
  const { identity, token, factorSid } = props

  const createPushForm = useFormik({
    initialValues: {
      logoUrl: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await createChallenge(identity, token, factorSid, values)
      resetForm()
    },
  })
  return (
    <Form onSubmit={createPushForm.handleSubmit} style={{ padding: '20px' }}>
      <Row form>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <h5>Create a Push Challenge</h5>
        </Col>
      </Row>
      <Row form>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <FormGroup>
            <Label for="logoUrl">Logo URL (optional, https links only)</Label>
            <Input
              type="url"
              name="logoUrl"
              placeholder="https://example.com/logo.png"
              pattern="https://.*"
              value={createPushForm.values.logoUrl}
              onChange={createPushForm.handleChange}
              invalid={
                createPushForm.touched.logoUrl &&
                Boolean(createPushForm.errors.logoUrl)
              }
            />
            <FormFeedback>{createPushForm.errors.logoUrl}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <FormGroup>
            <Label for="message">Challenge Message (optional)</Label>
            <Input
              type="text"
              name="message"
              placeholder="Sign-in attempt..."
              value={createPushForm.values.message}
              onChange={createPushForm.handleChange}
              invalid={
                createPushForm.touched.message &&
                Boolean(createPushForm.errors.message)
              }
            />
            <FormFeedback>{createPushForm.errors.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <Button
            type="submit"
            color="primary"
            disabled={createPushForm.isSubmitting}
          >
            {createPushForm.isSubmitting
              ? 'Creating challenge...'
              : 'Create Challenge'}
          </Button>
          {!createPushForm.isSubmitting && (
            <Button
              color="link"
              className="text-secondary"
              onClick={createPushForm.resetForm}
            >
              reset
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  )
}
