import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { withOktaAuth } from '@okta/okta-react'
import {
  Collapse,
  Navbar,
  Nav,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import * as Actions from '../../states/currentUser'
import config from '../../config'

class NavComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      allowedRoutesAndLinks: [],
    }

    this.toggle = this.toggle.bind(this)
    this.logout = this.logout.bind(this)
    this.createDynamicDropDown = this.createDynamicDropDown.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  async checkAuthentication() {
    if (this.props.authState.isAuthenticated && !this.props.currentUser) {
      let user = await this.props.oktaAuth.getUser()

      if (user === undefined) return

      const oktaGroups = user.groups

      user.solutionsengineer = oktaGroups.includes('Sales Solution Engineers')
      user.admin = oktaGroups.includes('DemoAdmins')
      user.partner = oktaGroups.includes('Partner Users')
      user.partnerAdmin = oktaGroups.includes('PartnerAdmins')
      user.accountexecutive = oktaGroups.includes('Account Executive')
      this.props.initUser({ user })
      this.setState({
        allowedRoutesAndLinks: this.createDynamicDropDown(user),
      })
    }
  }

  async logout() {
    await this.props.oktaAuth.signOut('/')
  }

  async componentDidMount() {
    if (this.props.authState.isAuthenticated) {
      await this.checkAuthentication()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.authState.isAuthenticated && prevProps.authState.isPending) {
      await this.checkAuthentication()
      const currentPathName = window.location.pathname
      const { allowedRoutesAndLinks } = this.state
      const filteredRoutes = allowedRoutesAndLinks.map(
        (allowedRouteAndLink) => {
          return allowedRouteAndLink.path
        },
      )
      if (
        !(currentPathName === '/home' || currentPathName === '/') &&
        !filteredRoutes.includes(currentPathName)
      ) {
        window.location.pathname = '/home'
      }
    }
  }

  createDynamicDropDown(user) {
    if (user) {
      const { navConfig } = config
      let navBarDropDown = []
      for (let navTitle of Object.keys(navConfig)) {
        const userKeys = Object.keys(user)
        for (let i = 0; i < userKeys.length; i++) {
          if (
            navConfig[navTitle].allow.includes(userKeys[i]) &&
            user[userKeys[i]]
          ) {
            navBarDropDown.push(navConfig[navTitle])
            break
          }
        }
      }
      return navBarDropDown
    }
  }

  render() {
    const { isAuthenticated } = this.props.authState
    const { allowedRoutesAndLinks } = this.state

    return (
      <div className="navigation">
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">Owl Demo</NavbarBrand>
          {isAuthenticated ? (
            <React.Fragment>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      {this.props.currentUser
                        ? this.props.currentUser.email
                        : ''}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {allowedRoutesAndLinks.map((allowedRouteAndLink) => {
                        return (
                          <DropdownItem key={allowedRouteAndLink.path}>
                            <NavLink
                              to={`${allowedRouteAndLink.path}`}
                              className="nav-link"
                            >
                              {`${allowedRouteAndLink.label}`}
                            </NavLink>
                          </DropdownItem>
                        )
                      })}
                      <DropdownItem>
                        <div className="nav-link" onClick={this.logout}>
                          Logout
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </React.Fragment>
          ) : (
            <Nav className="ml-auto" navbar>
              <NavItem
                onClick={() => {
                  window.location = '/'
                }}
              ></NavItem>
            </Nav>
          )}
        </Navbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.user,
})

const mapDispatchToProps = (dispatch) => ({
  initUser: bindActionCreators(Actions.initUser, dispatch),
})

export default withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(NavComp),
)
