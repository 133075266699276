import userService from '../services/userService'
const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING'
const FETCH_USERS = 'FETCH_USERS'
const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED'
const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED'

const UPDATE_USER = 'UPDATE_USER'
const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING'
const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED'
const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED'

const SELECT_USER = 'SELECT_USER'

const initialState = {
  user: {},
  loading: false,
  hasError: false,
  hasSubmitted: false,
  errorMessage: null,
  isUpdating: false,
}

export const fetchUsers = (authToken) => ({
  type: FETCH_USERS,
  payload: userService
    .getUsers(authToken)
    .then((users) => {
      //TODO handle this better
      if (users.status && users.status !== 200) {
        return {
          loading: false,
          hasError: true,
          errorMessage: users.body,
          users: {},
        }
      }

      return {
        loading: false,
        hasError: false,
        errorMessage: null,
        hasSubmitted: false,
        users,
      }
    })
    .catch(() => ({
      loading: false,
      hasError: true,
      hasSubmitted: false,
      errorMessage: 'Unknown Error',
      users: [],
    })),
})

export const updateUser = (authToken, user) => ({
  type: UPDATE_USER,
  payload: userService
    .updateUser(authToken, user)
    .then(async (selectedUser) => {
      return {
        loading: false,
        hasError: false,
        hasLoaded: true,
        errorMessage: null,
        hasSubmitted: true,
        isUpdating: false,
        selectedUser,
      }
    })
    .catch((err) => {
      return {
        loading: false,
        hasError: true,
        hasLoaded: true,
        errorMessage: err.message,
        isUpdating: false,
        selectedUser: user,
      }
    }),
})

export const selectUser = (user) => ({
  type: SELECT_USER,
  payload: { user },
})

export function reduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_PENDING:
      return {
        ...state,
        loading: true,
        hasError: false,
        errorMessage: null,
      }
    case FETCH_USERS_FULFILLED:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_USERS_REJECTED:
      return {
        ...state,
        loading: false,
        hasError: true,
        errorMessage: action.payload.message,
      }

    case UPDATE_USER_PENDING:
      return {
        ...state,
        hasError: false,
        errorMessage: null,
        isUpdating: true,
      }

    case UPDATE_USER_FULFILLED:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_USER_REJECTED:
      return {
        ...state,
        loading: false,
        hasError: true,
        errorMessage: action.payload?.message,
      }

    case SELECT_USER: {
      const selectedUser = { ...action.payload.user }
      return {
        ...state,
        selectedUser,
      }
    }

    default:
      return state
  }
}
