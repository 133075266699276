const INIT_CURRENTUSER = 'INIT_CURRENTUSER'

export const initUser = (userData) => ({
  type: INIT_CURRENTUSER,
  payload: userData,
})

export function reduce(state = {}, action) {
  switch (action.type) {
    case INIT_CURRENTUSER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
