import React, { useState } from 'react'
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Alert,
} from 'reactstrap'
import { useFormik } from 'formik'

import config from '../../config'
const { jobRoles, countries, accountTypes } = config

const baseUrl = process.env.REACT_APP_BASE_URL
const registerUrl = baseUrl + '/api/Register'

const createOptions = (list) => {
  return list.map((opt) => {
    if (typeof opt === 'string') return <option key={opt}>{opt}</option>
    return (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    )
  })
}

const roleOptions = createOptions(jobRoles)

const countryOptions = createOptions(countries)

const accountTypeOptions = createOptions(accountTypes)

const validate = (values) => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Must be 15 characters or less'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  } else if (values.lastName.length > 20) {
    errors.lastName = 'Must be 20 characters or less'
  }

  if (values.accountType === 'employee') {
    if (values.email.indexOf('twilio.com') < 1) {
      errors.email = 'Please use your twilio email address'
    }
  } else if (values.accountType === 'partner') {
    if (!values.company) {
      errors.company = 'Required'
    }
  }

  if (!values.email) {
    errors.email = 'Required'
  } else if (
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      values.email,
    )
  ) {
    errors.email = 'Invalid email address'
  }

  if (!values.phone) {
    errors.phone = 'Required'
  } else if (values.phone.indexOf('+') !== 0) {
    errors.phone = 'In e164 format, Examples: +13172222222 , +49152901820'
  }

  if (!values.title) {
    errors.title = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  return errors
}

export default function Register(props) {
  const [state, setState] = useState({
    hasError: false,
    errorMessage: '',
    hasSubmitted: false,
    isSubmitting: false,
  })

  const mode = props.registration ? 'edit' : 'create'

  let initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: 'Sales Engineering',
    country: 'United States',
    city: '',
    state: '',
    accountType: 'employee',
    partnerAgrees: false,
  }
  if (props.registration) {
    initialValues = props.registration
  }

  const createRegistration = async (values) => {
    try {
      const res = await fetch(registerUrl, {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      const msg = await res.json()
      console.log(msg)
      if (msg.status && msg.status === 409) {
        //toast registration already exists
        setState({
          hasError: true,
          errorMessage: 'Registration already exists',
        })
      } else if (msg.message && msg.message === 'Success') {
        setState({ hasSubmitted: true })
      } else {
        setState({
          hasError: true,
          errorMessage: 'Unknown error. Please reach out to #help-owldemo ',
        })
      }
    } catch (err) {
      console.log(err)
      if (err.type && err.type === 409) {
        //toast registration already exists
        setState({
          hasError: true,
          errorMessage: 'Error submitting registration, please try again',
        })
      }
    }
  }

  const editRegistration = async (values) => {
    try {
      const res = await fetch(registerUrl, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + props.token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      })
      const msg = await res.json()
      console.log(msg)
      if (msg.message && msg.message === 'Success') {
        setState({ hasSubmitted: true, isSubmitting: false })
      } else {
        setState({
          isSubmitting: false,
          hasError: true,
          errorMessage: 'Unknown error. Please reach out to #help-owldemo ',
        })
      }
    } catch (err) {
      console.log(err)
      if (err.type && err.type === 409) {
        //toast registration already exists
        setState({
          isSubmitting: false,
          hasError: true,
          errorMessage: 'Error submitting registration, please try again',
        })
      }
    }
  }

  const onFormSubmit = async (values) => {
    console.log(values)
    // set loading spinner
    setState({
      hasError: false,
      errorMessage: '',
      hasSubmitted: false,
      isSubmitting: true,
    })
    if (mode === 'create') createRegistration(values)
    else if (mode === 'edit') editRegistration(values)
  }

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: onFormSubmit,
  })

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                required
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <Alert color="warning">{formik.errors.firstName}</Alert>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                required
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <Alert color="warning">{formik.errors.lastName}</Alert>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="accountType">I am a</Label>
              <Input
                type="select"
                name="accountType"
                id="accountType"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountType}
              >
                {accountTypeOptions}
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="title">Job Role</Label>
              <Input
                type="select"
                name="title"
                id="title"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              >
                {roleOptions}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {formik.values.accountType === 'partner' ? (
          <Row form>
            <Col md={8}>
              <FormGroup>
                <Label for="company">Company</Label>
                <Input
                  type="text"
                  name="company"
                  id="company"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  required
                />
                {formik.errors.company && formik.touched.company ? (
                  <Alert color="warning">{formik.errors.company}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
              />
              {formik.errors.email && formik.touched.email ? (
                <Alert color="warning">{formik.errors.email}</Alert>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">Phone Number</Label>
              <Input
                type="tel"
                name="phone"
                id="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                required
              />
              {formik.errors.phone && formik.touched.phone ? (
                <Alert color="warning">{formik.errors.phone}</Alert>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input
                type="select"
                name="country"
                id="country"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
              >
                {countryOptions}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="city">City</Label>
              <Input
                type="text"
                name="city"
                id="city"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
            </FormGroup>
            {formik.errors.city && formik.touched.city ? (
              <Alert color="warning">{formik.errors.city}</Alert>
            ) : null}
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="state">State/Province/Region</Label>
              <Input
                type="text"
                name="state"
                id="state"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
              />
              {formik.errors.state && formik.touched.state ? (
                <Alert color="warning">{formik.errors.state}</Alert>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        {formik.values.accountType === 'partner' ? (
          <Row form>
            <Col md={9}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="partnerAgrees"
                    id="partnerAgrees"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.partnerAgrees}
                  />
                  As a condition of access to the Owl Demo Platform, all Partner
                  Sales people must agree to present the Owl Demo vertical to
                  their Twilio Practice Lead prior to demonstrating the Owl Demo
                  Platform to a customer. By ticking this check box, you agree
                  that you will present your chosen Owl Demo vertical to your
                  Twilio Practice Lead prior to demonstrating the Owl Demo
                  Platform to a customer. Twilio reserves the right to validate
                  successful completion presentation with the designated Twilio
                  Partner Practice Lead.
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}></Col>
          </Row>
        ) : null}
        {mode === 'create' ? (
          <Button
            color="primary"
            size="lg"
            className="registerSubmit"
            disabled={
              state.isSubmitting ||
              (formik.values.accountType === 'partner' &&
                !formik.values.partnerAgrees)
            }
          >
            Sign up
          </Button>
        ) : mode === 'edit' ? (
          <React.Fragment>
            <Button
              color="primary"
              size="lg"
              className="registerSubmit"
              disabled={state.isSubmitting}
            >
              Update
            </Button>
            <Button
              color="secondary"
              size="lg"
              className="registerSubmit"
              style={{ marginRight: 16 }}
              onClick={props.toggle}
            >
              Close
            </Button>
          </React.Fragment>
        ) : null}
        <br />
        <br />
        {state.hasError ? (
          <Alert color="danger">{state.errorMessage}</Alert>
        ) : null}
        {state.hasSubmitted && mode === 'create' ? (
          <Alert color="success">Registration submitted!</Alert>
        ) : null}
        {state.hasSubmitted && mode === 'edit' ? (
          <Alert color="success">Registration updated!</Alert>
        ) : null}
      </Form>
    </Container>
  )
}
