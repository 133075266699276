import React from 'react'
import { MdRefresh } from 'react-icons/md'
import { Spinner, Button, Table } from 'reactstrap'

import TaskRow from './TaskRow'

const TaskTable = (props) => {
  const { tasks, tasksLoading, refreshTasks, deleteTask } = props

  return (
    <div>
      <h2>
        Active Tasks
        <Button
          color="secondary"
          onClick={refreshTasks}
          disabled={tasksLoading}
          style={{ marginLeft: 16 }}
        >
          <MdRefresh />
        </Button>
      </h2>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Created At</th>
            <th>Age</th>
            <th>Channel Type</th>
            <th>From</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tasksLoading ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                <Spinner
                  size="lg"
                  color="secondary"
                  style={{ margin: 'auto' }}
                />
              </td>
            </tr>
          ) : (
            tasks.map((task) => (
              <TaskRow task={task} onDelete={deleteTask} key={task.sid} />
            ))
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default TaskTable
