import React from 'react'
import {
  Row,
  Alert,
  Label,
  Col,
  Input,
  Form,
  FormGroup,
  Button,
  Spinner,
} from 'reactstrap'

import { Formik } from 'formik'

const validate = (values) => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Must be 15 characters or less'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  } else if (values.lastName.length > 20) {
    errors.lastName = 'Must be 20 characters or less'
  }

  if (!values.email) {
    errors.email = 'Required'
  }

  if (!values.oktaEmail) {
    errors.oktaEmail = 'Required'
  }

  if (!values.title) {
    errors.title = 'Required'
  }

  if (!values.country) {
    errors.country = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  return errors
}

export default function UserForm(props) {
  const initVals = { ...props.user }
  const { hasError, hasSubmitted, errorMessage, isUpdating, toggle } = props

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={async (values) => {
        props.updateUser(values)
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={props.handleChange}
                  value={props.values.firstName}
                  required
                />
                {props.errors.lastName ? (
                  <Alert color="warning">{props.errors.lastName}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={props.handleChange}
                  value={props.values.lastName}
                  required
                />
                {props.errors.firstName ? (
                  <Alert color="warning">{props.errors.firstName}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="company">Company</Label>
                <Input
                  type="text"
                  name="company"
                  id="company"
                  onChange={props.handleChange}
                  value={props.values.company}
                  required
                />
                {props.errors.company ? (
                  <Alert color="warning">{props.errors.company}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="title">Job Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  onChange={props.handleChange}
                  value={props.values.title}
                  required
                />
                {props.errors.title ? (
                  <Alert color="warning">{props.errors.title}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  onChange={props.handleChange}
                  value={props.values.email}
                  required
                />
                {props.errors.email ? (
                  <Alert color="warning">{props.errors.email}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="oktaEmail">Okta Login Email</Label>
                <Input
                  type="text"
                  name="oktaEmail"
                  id="oktaEmail"
                  onChange={props.handleChange}
                  value={props.values.oktaEmail}
                  required
                />
                {props.errors.oktaEmail ? (
                  <Alert color="warning">{props.errors.oktaEmail}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="country">Country</Label>
                <Input
                  type="text"
                  name="country"
                  id="country"
                  onChange={props.handleChange}
                  value={props.values.country}
                  required
                />
                {props.errors.country ? (
                  <Alert color="warning">{props.errors.country}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  onChange={props.handleChange}
                  value={props.values.state}
                  required
                />
                {props.errors.state ? (
                  <Alert color="warning">{props.errors.state}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  onChange={props.handleChange}
                  value={props.values.city}
                  required
                />
                {props.errors.city ? (
                  <Alert color="warning">{props.errors.city}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Button
            type="submit"
            color="primary"
            disabled={isUpdating}
            className="registerSubmit"
          >
            Update
          </Button>
          <Button
            color="secondary"
            size="lg"
            className="registerSubmit"
            style={{ marginRight: 16 }}
            onClick={toggle}
          >
            Close
          </Button>
          {isUpdating ? <Spinner size="lg" color="secondary" /> : null}
          <br />
          <br />
          {hasError && <Alert color="danger">{errorMessage}</Alert>}
          {hasSubmitted && (
            <Alert color="success">User record submitted!</Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}
