import React from 'react'
import { MdRefresh } from 'react-icons/md'
import { Spinner, Button, Table } from 'reactstrap'

import ConversationRow from './ConversationRow'

const ConversationTable = (props) => {
  const {
    conversations,
    conversationsLoading,
    refreshConversations,
    deleteConversation,
  } = props

  return (
    <div>
      <h2>
        Active Conversations
        <Button
          color="secondary"
          onClick={refreshConversations}
          disabled={conversationsLoading}
          style={{ marginLeft: 16 }}
        >
          <MdRefresh />
        </Button>
      </h2>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Created At</th>
            <th>Age</th>
            <th>Friendly Name</th>
            <th>From</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {conversationsLoading ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                <Spinner
                  size="lg"
                  color="secondary"
                  style={{ margin: 'auto' }}
                />
              </td>
            </tr>
          ) : (
            conversations.map((conversation) => (
              <ConversationRow
                conversation={conversation}
                onDelete={deleteConversation}
                key={conversation.sid}
              />
            ))
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default ConversationTable
