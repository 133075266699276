import React, { Component } from 'react'
import { Verify } from '@demoeng/usecase-verify'
import { connect } from 'react-redux'
import { withOktaAuth } from '@okta/okta-react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as Actions from '../states/customer'

class Customer extends Component {
  componentDidMount() {
    if (this.props.currentUser) this.getCustomer()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) this.getCustomer()
  }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  getCustomer = async () => {
    let email
    if (this.props.isAdmin) {
      email = this.props.user?.email
    } else {
      email = this.props.currentUser?.email
    }
    console.log('fetching customer record', email)
    this.props.fetchCustomer(this._getToken(), email)
  }

  render() {
    return <Verify />
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer.customer,
  user: state.users.selectedUser,
  currentUser: state.currentUser.user,
})

const mapDispatchToProps = function (dispatch) {
  return {
    fetchCustomer: bindActionCreators(Actions.customerFetchData, dispatch),
    updateCustomer: bindActionCreators(Actions.updateCustomerData, dispatch),
  }
}

export default withRouter(
  withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Customer)),
)
