import { reduce as currentUserReducer } from './currentUser'
import { reduce as usersReducer } from './users'
import { reduce as customerReducer } from './customer'
import { reduce as demoConfigReducer } from './demoConfig'
import { reduce as chatReducer } from './webChat'

export const owlDemoReducers = {
  currentUser: currentUserReducer,
  users: usersReducer,
  customer: customerReducer,
  demoConfig: demoConfigReducer,
  webchat: chatReducer,
}
