import React from 'react'
import { MdRefresh } from 'react-icons/md'
import { Spinner, Button, Table } from 'reactstrap'

import ProxyRow from './ProxyRow'

const ProxyTable = (props) => {
  const { proxySessions, proxyLoading, refreshProxy, deleteProxySession } =
    props

  return (
    <div>
      <h2>
        Active Proxy Sessions
        <Button
          color="secondary"
          onClick={refreshProxy}
          disabled={proxyLoading}
          style={{ marginLeft: 16 }}
        >
          <MdRefresh />
        </Button>
      </h2>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Created At</th>
            <th>Age</th>
            <th>Friendly Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {proxyLoading ? (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                <Spinner
                  size="lg"
                  color="secondary"
                  style={{ margin: 'auto' }}
                />
              </td>
            </tr>
          ) : (
            proxySessions.map((session) => (
              <ProxyRow
                session={session}
                onDelete={deleteProxySession}
                key={session.sid}
              />
            ))
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default ProxyTable
