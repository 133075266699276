import React, { useState } from 'react'

import {
  Row,
  Button,
  Spinner,
  Table,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { MdModeEdit, MdPerson, MdSettings } from 'react-icons/md'

export default function UserList(props) {
  const {
    users,
    loading,
    editUser,
    openCustomer,
    openDemoConfig,
    admin,
    partnerAdmin,
  } = props

  const defaultGroup = partnerAdmin && !admin ? 'Partners' : 'All'
  const [groupType, setGroupType] = useState(defaultGroup)

  const groupChange = (e) => {
    setGroupType(e.target.value)
  }

  const displayUsers =
    groupType === 'All'
      ? users
      : users?.filter((user) => {
          if (groupType === 'Partners') return user.partner
          if (groupType === 'Internal') return !user.partner
          return false
        })

  return (
    <React.Fragment>
      <Row style={{ justifyContent: 'space-between' }}>
        <h1>Demo Users</h1>
        {loading ?? null}
        {admin ? (
          <FormGroup>
            <Label for="exampleSelect">Group Type</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={groupType}
              onChange={groupChange}
            >
              <option>All</option>
              <option>Internal</option>
              <option>Partners</option>
            </Input>
          </FormGroup>
        ) : null}
      </Row>
      <Row>
        <Table striped responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {displayUsers && displayUsers.length > 0 ? (
              displayUsers.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.status}</td>
                    <td>
                      <Button
                        color="warning"
                        onClick={() => {
                          editUser(user)
                        }}
                        style={{ marginLeft: 6 }}
                      >
                        <MdModeEdit />
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          openCustomer(user)
                        }}
                        style={{ marginLeft: 6 }}
                      >
                        <MdPerson />
                      </Button>
                      <Button
                        color="info"
                        onClick={() => {
                          openDemoConfig(user)
                        }}
                        style={{ marginLeft: 6 }}
                      >
                        <MdSettings />
                      </Button>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center' }}>
                  <Spinner
                    size="lg"
                    color="secondary"
                    style={{ margin: 'auto' }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
    </React.Fragment>
  )
}
