import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import UserForm from './UserForm'

export default function UserModal(props) {
  const {
    user,
    toggle,
    open,
    updateUser,
    errorMessage,
    hasSubmitted,
    isUpdating,
  } = props
  return (
    <Modal isOpen={open} toggle={toggle} size="lg" backdrop={true}>
      <ModalHeader toggle={toggle}>
        User {user?.firstName} {user?.lastName}
      </ModalHeader>
      <ModalBody>
        <UserForm
          user={user}
          updateUser={updateUser}
          errorMessage={errorMessage}
          hasSubmitted={hasSubmitted}
          isUpdating={isUpdating}
          toggle={toggle}
        />
      </ModalBody>
    </Modal>
  )
}
