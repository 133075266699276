const baseUrl = process.env.REACT_APP_BASE_URL
const getUrl = baseUrl + '/api/DemoConfiguration/'
const updateUrl = baseUrl + '/api/DemoConfiguration/'

class DemoConfigService {
  _getHeaders = (token) => {
    return {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  getDemoConfig = (token, customerId) => {
    const headers = this._getHeaders(token)
    return fetch(getUrl + customerId, {
      method: 'GET',
      headers,
    }).then((config) => {
      return config.json()
    })
  }

  updateDemoConfig = (token, customerId, config) => {
    const headers = this._getHeaders(token)
    return fetch(updateUrl + customerId, {
      method: 'PUT',
      body: JSON.stringify(config),
      headers,
    }).then((config) => {
      return config.json()
    })
  }
}

const demoConfigService = new DemoConfigService()

export default demoConfigService
