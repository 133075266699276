import React from 'react'
import { Col, Row, Button, Input, Alert, FormText, Spinner } from 'reactstrap'

import config from '../../config'
const {
  demoOptions,
  crmOptions,
  outboundOptions,
  outboundSmsOptions,
  transcriptionOptions,
} = config

class SettingsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDemo: props.demoConfig.activeDemo,
      activeCrm: props.demoConfig.activeCrm,
      activeTranscription: props.demoConfig.activeTranscription,
      preferredOutbound: props.demoConfig.preferredOutbound,
      preferredSmsOutbound: props.demoConfig.preferredSmsOutbound,
      currentDemoCustomization: props.demoConfig.currentDemoCustomization,
    }
  }

  setCustomization = (activeDemo) => {
    let newCustomization = ''
    this.props.demoConfig.customizations.forEach((custom) => {
      if (custom.activeDemo === activeDemo) {
        newCustomization = custom.customization
      }
    })
    this.setState({
      currentDemoCustomization: newCustomization,
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name === 'activeDemo') {
      if (this.state.activeDemo !== e.target.value) {
        this.setCustomization(e.target.value)
      }
    }
  }

  onUpdateClick = () => {
    this.setState({ hasSubmitted: true })
    this.props.onUpdate(this.state)
  }

  render() {
    if (!this.props.demoConfig) {
      return (
        <Row>
          <Spinner size="lg" color="secondary" />
        </Row>
      )
    }

    const { isUpdating, hasSubmitted, hasError, errorMessage, admin } =
      this.props
    const {
      activeDemo,
      activeCrm,
      activeTranscription,
      preferredOutbound,
      preferredSmsOutbound,
      currentDemoCustomization,
    } = this.state
    return (
      <Row>
        <Col md="6">
          <h3>Active Demo:</h3>
          <Input
            type="select"
            name="activeDemo"
            className="form-control"
            value={activeDemo}
            onChange={this.onChange}
          >
            {demoOptions.map((opt) => {
              return (
                <option value={opt.value} key={'demoOpt-' + opt.value}>
                  {opt.label}
                </option>
              )
            })}
          </Input>
        </Col>
        <Col md="6">
          <h3>Active CRM:</h3>
          <Input
            type="select"
            name="activeCrm"
            className="form-control"
            value={activeCrm}
            onChange={this.onChange}
          >
            {crmOptions.map((opt) => {
              return (
                <option value={opt.value} key={'crmOpt-' + opt.value}>
                  {opt.label}
                </option>
              )
            })}
          </Input>
        </Col>
        <Col xs="12">
          <br />
        </Col>
        <Col md="6">
          <h3>Preferred Voice Number:</h3>
          <Input
            type="select"
            name="preferredOutbound"
            className="form-control"
            value={preferredOutbound}
            onChange={this.onChange}
          >
            {outboundOptions.map((opt) => {
              return (
                <option value={opt.value} key={'outOpt-' + opt.value}>
                  {opt.label}
                </option>
              )
            })}
          </Input>
          <FormText color="muted">
            This is the preferred voice phone number for the system to call
            from.
          </FormText>
        </Col>
        <Col md="6">
          <h3>Preferred SMS Number:</h3>
          <Input
            type="select"
            name="preferredSmsOutbound"
            className="form-control"
            value={preferredSmsOutbound}
            onChange={this.onChange}
          >
            {outboundSmsOptions.map((opt) => {
              return (
                <option value={opt.value} key={'outOpt-' + opt.value}>
                  {opt.label}
                </option>
              )
            })}
          </Input>
          <FormText color="muted">
            This is the preferred sms phone number for the system to message
            from.
          </FormText>
        </Col>
        <Col xs="12">
          <br />
        </Col>
        {!this.props.partner ? (
          <Col md="6">
            <h3>Voice Transcription:</h3>
            <Input
              type="select"
              name="activeTranscription"
              className="form-control"
              value={activeTranscription}
              onChange={this.onChange}
            >
              {transcriptionOptions.map((opt) => {
                return (
                  <option value={opt.value} key={'outOpt-' + opt.value}>
                    {opt.label}
                  </option>
                )
              })}
            </Input>
            <FormText color="muted">
              This enables real-time voice transcription from various third
              party vendors.
            </FormText>
          </Col>
        ) : null}
        <Col xs="12">
          <br />
        </Col>
        <Col xs="12">
          <h3>Current Customization:</h3>
          <Col sm={10}>
            <Input
              type="textarea"
              name="currentDemoCustomization"
              className="form-control"
              value={currentDemoCustomization}
              onChange={this.onChange}
              readOnly={!admin}
            />
          </Col>
        </Col>
        <Col xs="12">
          <br />
        </Col>
        <Col xs="12">
          <br />
        </Col>
        <Col xs="12">
          <Button
            name="update"
            onClick={this.onUpdateClick}
            disabled={isUpdating}
          >
            Update
          </Button>
          {isUpdating ? <Spinner size="lg" color="secondary" /> : null}
        </Col>
        <Col xs="12">
          <br />
        </Col>
        {hasSubmitted && (
          <Col xs="12">
            <Alert color="success">Configuration updated!</Alert>
          </Col>
        )}
        {hasError && (
          <Col xs="12">
            <Alert color="error">
              Error updating!
              <br />
              {errorMessage}
            </Alert>
          </Col>
        )}
      </Row>
    )
  }
}

export default SettingsForm
