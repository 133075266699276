import React from 'react'
import { Row, Col, Spinner, Table } from 'reactstrap'
import FactorPushRow from './FactorPushRow'

const FactorsPushTable = (props) => {
  const { factors, factorsLoading, deleteFactor } = props

  return (
    <Row style={{ padding: '20px' }}>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <h5>Push Factors</h5>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Created At</th>
              <th>Sid</th>
              <th>Friendly Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {factorsLoading ? (
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <Spinner
                    size="lg"
                    color="secondary"
                    style={{ margin: 'auto' }}
                  />
                </td>
              </tr>
            ) : (
              factors
                .filter((factor) => factor.factorType === 'push')
                .map((pushFactor) => (
                  <FactorPushRow
                    key={pushFactor.sid}
                    factor={pushFactor}
                    onDelete={deleteFactor}
                  />
                ))
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default FactorsPushTable
