import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withOktaAuth } from '@okta/okta-react'
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap'
import classnames from 'classnames'
import FactorsPushTable from './FactorsPushTable'
import FactorsTotpTable from './FactorsTotpTable'
import PushChallengeForm from './PushChallengeForm'
import CreateTotpFactorForm from './CreateTotpFactorForm'
import verifyService from '../../services/verifyService'

const MOBILE_APP_WIKI_URL =
  'https://wiki.hq.twilio.com/display/SALESENG/Mobile+App'

class VerifyFactors extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'tab-push',
      factors: [],
      factorsLoading: false,
      isCreatingFactor: false,
      isVerifyingFactor: false,
      isCancelling: false,
    }
  }

  async componentDidMount() {
    if (this.props.currentUser) {
      await this.getFactors()
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      await this.getFactors()
    }
  }

  _getToken = () => {
    return this.props.authState.accessToken.accessToken
  }

  getCustomer = async () => {
    await this.props.fetchCustomer(
      this._getToken(),
      this.props.currentUser.email,
    )
  }

  getFactors = async () => {
    this.setState({ factorsLoading: true })
    const token = this._getToken()
    const identity = this.props.currentUser.email
    const entityId = await verifyService.getEntityId(identity, token)
    const { success, factors } = await verifyService.getFactors(entityId, token)
    if (success) {
      this.setState({
        factors,
        factorsLoading: false,
      })
    }
  }

  removeItem = (items, item) => {
    const index = items.findIndex((obj) => obj.sid === item.sid)
    if (index > -1) {
      items.splice(index, 1)
      return true
    }
    return false
  }

  setIsDeleting(items, item) {
    let matchingIndex = items.findIndex((obj) => obj.sid === item.sid)
    items[matchingIndex].isDeleting = true
  }

  deleteFactor = async (factor) => {
    const factors = this.state.factors
    this.setIsDeleting(factors, factor)
    this.setState({ factors })
    const token = this._getToken()
    const identity = this.props.currentUser.email
    const entityId = await verifyService.getEntityId(identity, token)
    const { success } = await verifyService.deleteFactor(
      entityId,
      token,
      factor.sid,
    )
    if (success) {
      if (this.removeItem(factors, factor)) {
        this.setState({ factors })
      }
    }
  }

  createTotpFactor = async (identity, token, accountName) => {
    this.setState({ isCreatingFactor: true })
    const entityId = await verifyService.getEntityId(identity, token)
    const res = await verifyService.createFactor(
      entityId,
      token,
      'totp',
      accountName,
    )

    if (res.success) {
      this.setState({ isCreatingFactor: false, isVerifyingFactor: true })
    }
    return res
  }

  verifyTotpFactor = async (identity, token, factorSid, code) => {
    this.setState({ isVerifyingFactor: true })
    const entityId = await verifyService.getEntityId(identity, token)
    const res = await verifyService.verifyNewFactor(
      entityId,
      token,
      factorSid,
      code,
    )
    if (res.success) {
      this.setState({ factors: [...this.state.factors, res.factor] })
      this.setState({ isVerifyingFactor: false })
    }
    this.setState({ isCreatingFactor: false })
    return res
  }

  cancelCreatingTotpFactor = async (identity, token, factorSid) => {
    this.setState({ isCancelling: true })
    const entityId = await verifyService.getEntityId(identity, token)
    const res = await verifyService.deleteFactor(entityId, token, factorSid)
    if (res.success) {
      this.setState({
        isCreatingFactor: false,
        isVerifyingFactor: false,
        isCancelling: false,
      })
    }
    return res
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  render() {
    const { factors, factorsLoading, activeTab } = this.state

    const pushFactors = factors
      .filter((f) => f.factorType === 'push')
      .sort((a, b) => b.dateCreated - a.dateCreated)

    const totpFactors = factors
      .filter((f) => f.factorType === 'totp')
      .sort((a, b) => b.dateCreated - a.dateCreated)

    const user = this.props.currentUser

    if (!user || factorsLoading) {
      return (
        <Container style={{ padding: '20px' }}>
          <Row>
            <Col>
              <h2>Verify Factors</h2>
              <Spinner size="lg" color="secondary" />
            </Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container>
          <Row>
            <Col>
              <h2>Verify Factors</h2>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 'tab-push',
                    })}
                    onClick={() => this.toggleTab('tab-push')}
                  >
                    <h5
                      className={classnames({
                        'text-primary': this.state.activeTab === 'tab-push',
                      })}
                    >
                      Push
                    </h5>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 'tab-totp',
                    })}
                    onClick={() => this.toggleTab('tab-totp')}
                  >
                    <h5
                      className={classnames({
                        'text-primary': this.state.activeTab === 'tab-totp',
                      })}
                    >
                      TOTP
                    </h5>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="tab-push">
                  {!pushFactors || pushFactors.length < 1 ? (
                    <Container>
                      <Row>
                        <Col sm="12" md={{ size: 8, offset: 2 }}>
                          <div style={{ padding: '20px' }}>
                            <p>You have no push factors.</p>
                            <p>
                              Create one using the{' '}
                              <a
                                href={MOBILE_APP_WIKI_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Mobile App
                              </a>
                              .
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    <Container>
                      <PushChallengeForm
                        identity={user.email}
                        token={this._getToken()}
                        factorSid={pushFactors[0].sid}
                      />
                      <FactorsPushTable
                        factors={factors}
                        factorsLoading={factorsLoading}
                        deleteFactor={this.deleteFactor}
                      />
                    </Container>
                  )}
                </TabPane>
                <TabPane tabId="tab-totp">
                  <Container>
                    <Row>
                      <Col>
                        <CreateTotpFactorForm
                          identity={user.email}
                          token={this._getToken()}
                          createTotpFactor={this.createTotpFactor}
                          cancelCreatingTotpFactor={
                            this.cancelCreatingTotpFactor
                          }
                          verifyTotpFactor={this.verifyTotpFactor}
                          isCreatingFactor={this.state.isCreatingFactor}
                          isVerifyingFactor={this.state.isVerifyingFactor}
                          isCancelling={this.state.isCancelling}
                        />
                      </Col>
                    </Row>
                    {!totpFactors || totpFactors.length < 1 ? (
                      <Row>
                        <Col sm="12" md={{ size: 8, offset: 2 }}>
                          {!this.state.isCreatingFactor && (
                            <div style={{ padding: '20px' }}>
                              <p>You currently have no TOTP factors.</p>
                            </div>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <FactorsTotpTable
                            factors={factors}
                            factorsLoading={factorsLoading}
                            deleteFactor={this.deleteFactor}
                          />
                        </Col>
                      </Row>
                    )}
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.user,
})

export default withOktaAuth(connect(mapStateToProps, null)(VerifyFactors))
