const INIT_WEBCHAT = 'INIT_WEBCHAT'
const STARTED_WEBCHAT = 'STARTED_WEBCHAT'

export const startWebChat = () => ({
  type: INIT_WEBCHAT,
})

export const startedWebChat = (payload) => ({
  type: STARTED_WEBCHAT,
  payload,
})

export function reduce(state = {}, action) {
  switch (action.type) {
    case INIT_WEBCHAT:
      return {
        ...state,
        pending: true,
      }
    case STARTED_WEBCHAT:
      return {
        ...state,
        pending: false,
        started: true,
        manager: action.payload,
      }
    default:
      return state
  }
}
