import React from 'react'
import Moment from 'react-moment'
import { Spinner, Button } from 'reactstrap'

const TaskRow = (props) => {
  const { task, onDelete } = props
  return (
    <tr>
      <td>
        <Moment format="YYYY-MM-DD HH:mm" date={task.created_at}></Moment>
      </td>
      <td>
        <Moment date={task.created_at} durationFromNow />
      </td>
      <td>{task.channelType}</td>
      <td>{task.from}</td>
      <td>{task.assignmentStatus}</td>
      <td>
        {task.canDelete &&
          task.assignmentStatus !== 'completed' &&
          task.assignmentStatus !== 'canceled' && (
            <Button
              color="danger"
              onClick={() => {
                onDelete(task)
              }}
              disabled={task.isDeleting}
            >
              Disconnect
            </Button>
          )}
        {task.isDeleting && <Spinner size="sm" color="secondary" />}
      </td>
    </tr>
  )
}

export default TaskRow
