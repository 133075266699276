import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'
import { Button, Form } from 'reactstrap'

export default withOktaAuth(
  class Login extends Component {
    constructor(props) {
      super(props)
      this.state = { authenticated: null }
      this.checkAuthentication = this.checkAuthentication.bind(this)
      this.checkAuthentication()
      this.login = this.login.bind(this)
      this.logout = this.logout.bind(this)
    }

    async checkAuthentication() {
      const authenticated = await this.props.authState.isAuthenticated
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated })
      }
    }

    componentDidUpdate() {
      this.checkAuthentication()
    }

    async login() {
      // Redirect to '/' after login
      this.props.oktaAuth.signInWithRedirect('/settings')
    }

    async logout() {
      // Redirect to '/' after logout
      this.props.oktaAuth.signOut('/')
    }

    async register() {
      window.location = '/register'
    }

    render() {
      if (this.state.authenticated === null) {
        return null
      }
      return this.state.authenticated ? (
        <Redirect to="/home" />
      ) : (
        <Form className="form-signin">
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <Button onClick={this.login}>Sign In</Button>
          <br />
          <br />
          <Button onClick={this.register}>Register</Button>
        </Form>
      )
    }
  },
)
