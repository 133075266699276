import React from 'react'
import {
  Row,
  Alert,
  Label,
  Col,
  Input,
  Form,
  FormGroup,
  FormText,
  Button,
  Spinner,
} from 'reactstrap'

import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Formik, FieldArray } from 'formik'

const hubspotBase = process.env.REACT_APP_HB_BASE_URL
const sfdcBase = process.env.REACT_APP_SFDC_BASE_URL
const sfdcBaseEnd = '/view'
const zdBase = process.env.REACT_APP_ZD_BASE_URL
const zdBaseEnd = '/requested_tickets'

const validate = (values) => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Must be 15 characters or less'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  } else if (values.lastName.length > 20) {
    errors.lastName = 'Must be 20 characters or less'
  }

  if (!values.title) {
    errors.title = 'Required'
  }

  if (!values.country) {
    errors.country = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  if (!values.addrLine) {
    errors.addrLine = 'Required'
  }

  if (!values.addrLine) {
    errors.zipCode = 'Required'
  }

  let duplicatePrimaryPhones =
    values.phones.filter((ph) => ph.isPrimary).length > 1
  let noPrimaryPhones = values.phones.filter((ph) => ph.isPrimary).length === 0

  if (duplicatePrimaryPhones) {
    errors.phone = 'Select only 1 primary phone'
  }

  if (noPrimaryPhones) {
    errors.phone = 'Select 1 primary phone'
  }

  let duplicatePrimaryEmails =
    values.emails.filter((em) => em.isPrimary).length > 1
  let noPrimaryEmails = values.emails.filter((em) => em.isPrimary).length === 0

  if (duplicatePrimaryEmails) {
    errors.emails = 'Select only 1 primary email'
  }

  if (noPrimaryEmails) {
    errors.emails = 'Select 1 primary email'
  }

  return errors
}

export default function CustomerForm(props) {
  const defaultNewId = {
    id: '',
    userId: props.customer.id,
  }
  const defaultNewPhone = {
    ...defaultNewId,
    recordType: 'phone',
  }
  const defaultNewEmail = {
    ...defaultNewId,
    recordType: 'email',
  }
  const defaultNewSocial = {
    ...defaultNewId,
  }
  const initVals = { ...props.customer }
  const { hasError, hasSubmitted, errorMessage, isUpdating } = props

  const isReadOnlyEmail = (email) => {
    return (
      email.id &&
      (email.id.indexOf('twilio.com') !== -1 ||
        email.id.indexOf('owldemo.com') !== -1)
    )
  }

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={async (values) => {
        props.updateCustomer(values)
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <p>
            The customer record is the persona that is used in the Owl Demos for
            the contact that calls into Flex. These values are synchronized to
            the CRMs.
          </p>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={props.handleChange}
                  value={props.values.firstName}
                  required
                />
                {props.errors.lastName ? (
                  <Alert color="warning">{props.errors.lastName}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={props.handleChange}
                  value={props.values.lastName}
                  required
                />
                {props.errors.firstName ? (
                  <Alert color="warning">{props.errors.firstName}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="title">Job Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  onChange={props.handleChange}
                  value={props.values.title}
                  required
                />
                {props.errors.title ? (
                  <Alert color="warning">{props.errors.title}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="country">Country</Label>
                <Input
                  type="text"
                  name="country"
                  id="country"
                  onChange={props.handleChange}
                  value={props.values.country}
                  required
                />
                {props.errors.country ? (
                  <Alert color="warning">{props.errors.country}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  onChange={props.handleChange}
                  value={props.values.state}
                  required
                />
                {props.errors.state ? (
                  <Alert color="warning">{props.errors.state}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  onChange={props.handleChange}
                  value={props.values.city}
                  required
                />
                {props.errors.city ? (
                  <Alert color="warning">{props.errors.city}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="zipCode">Zip Code</Label>
                <Input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  onChange={props.handleChange}
                  value={props.values.zipCode}
                  required
                />
                {props.errors.zipCode ? (
                  <Alert color="warning">{props.errors.zipCode}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={9}>
              <FormGroup>
                <Label for="addrLine">Address Line</Label>
                <Input
                  type="text"
                  name="addrLine"
                  id="addrLine"
                  onChange={props.handleChange}
                  value={props.values.addrLine}
                  required
                />
                {props.errors.addrLine ? (
                  <Alert color="warning">{props.errors.addrLine}</Alert>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <h3>Phone Numbers</h3>
          </Row>
          <FieldArray
            name="phones"
            render={(arrayHelpers) => (
              <React.Fragment>
                {props.values.phones && props.values.phones.length > 0
                  ? props.values.phones.map((phone, idx) => (
                      <Row form key={`phones.${idx}`}>
                        <Col md={9}>
                          <FormGroup>
                            <Label for={`phones.${idx}`}>Phone Number</Label>
                            <Button
                              type="button"
                              style={{ marginLeft: 10 }}
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              <FontAwesomeIcon icon={faMinusCircle} />
                            </Button>
                            <Input
                              type="tel"
                              name={`phones[${idx}].id`}
                              id={`phones.${idx}`}
                              onChange={props.handleChange}
                              value={props.values.phones[idx].id}
                              pattern="\+[0-9]{8,15}"
                              required
                            />
                            <Label for={`phones.${idx}`}>Primary</Label>
                            <Input
                              type="checkbox"
                              name={`phones[${idx}].isPrimary`}
                              id={`phones.${idx}`}
                              onChange={props.handleChange}
                              value={props.values.phones[idx].isPrimary}
                              checked={props.values.phones[idx].isPrimary}
                              style={{ marginLeft: 25 }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))
                  : null}
                <Button
                  type="button"
                  outline
                  color="success"
                  onClick={() => arrayHelpers.push({ ...defaultNewPhone })}
                >
                  Add a phone number
                </Button>
                <FormText color="muted">
                  The primary phone number is used for notifications from Owl
                  Demo. <br />
                  Updating the primary phone number can take up to 5 minutes to
                  be reflected in the demo.
                </FormText>
              </React.Fragment>
            )}
          />
          {props.errors.phone ? (
            <Alert color="warning">{props.errors.phone}</Alert>
          ) : null}
          <Row form>
            <br />
          </Row>
          <Row form>
            <h3>Emails</h3>
          </Row>
          <FieldArray
            name="emails"
            render={(arrayHelpers) => (
              <React.Fragment>
                {props.values.emails && props.values.emails.length > 0
                  ? props.values.emails.map((email, idx) => (
                      <Row form key={`emails.${idx}`}>
                        <Col md={9}>
                          <FormGroup>
                            <Label for={`emails.${idx}`}>Email Address</Label>
                            {isReadOnlyEmail(props.values.emails[idx]) ? (
                              ''
                            ) : (
                              <Button
                                type="button"
                                style={{ marginLeft: 10 }}
                                onClick={() => arrayHelpers.remove(idx)}
                              >
                                <FontAwesomeIcon icon={faMinusCircle} />
                              </Button>
                            )}
                            <Input
                              type="text"
                              name={`emails[${idx}].id`}
                              onChange={props.handleChange}
                              value={props.values.emails[idx].id}
                              required
                              readOnly={isReadOnlyEmail(
                                props.values.emails[idx],
                              )}
                            />
                            <Label for={`phones.${idx}`}>Primary</Label>
                            <Input
                              type="checkbox"
                              name={`emails[${idx}].isPrimary`}
                              id={`emails.${idx}`}
                              onChange={props.handleChange}
                              value={props.values.emails[idx].isPrimary}
                              checked={props.values.emails[idx].isPrimary}
                              style={{ marginLeft: 25 }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))
                  : null}
                <Button
                  type="button"
                  outline
                  color="success"
                  onClick={() => arrayHelpers.push({ ...defaultNewEmail })}
                >
                  Add an email
                </Button>
                <FormText color="muted">
                  The primary email is used for notifications from Owl Demo.
                  <br />
                  Updating the primary email can take up to 5 minutes to be
                  reflected in the demo.
                </FormText>
              </React.Fragment>
            )}
          />
          {props.errors.emails ? (
            <Alert color="warning">{props.errors.emails}</Alert>
          ) : null}
          <Row form>
            <br />
          </Row>
          <Row form>
            <h3>Additional Channels</h3>
          </Row>
          <FieldArray
            name="socials"
            render={(arrayHelpers) => (
              <React.Fragment>
                {props.values.socials && props.values.socials.length > 0
                  ? props.values.socials.map((social, idx) => (
                      <Row form key={`socials.${idx}`}>
                        <Col md={9}>
                          <FormGroup>
                            <div>
                              <Label for={`socials.${idx}`}>Channel</Label>
                              <Button
                                type="button"
                                style={{ marginLeft: 10 }}
                                onClick={() => arrayHelpers.remove(idx)}
                              >
                                <FontAwesomeIcon icon={faMinusCircle} />
                              </Button>
                            </div>
                            <Input
                              type="select"
                              name={`socials[${idx}].recordType`}
                              onChange={props.handleChange}
                              value={props.values.socials[idx].recordType}
                              style={{
                                display: 'inline-flex',
                                width: '25%',
                                marginRight: '15px',
                              }}
                            >
                              <option value="" label="Account Type"></option>
                              <option value="line" label="Line">
                                Line
                              </option>
                              <option value="fbm" label="Facebook Messenger">
                                Facebook Messenger
                              </option>
                              <option value="wa" label="Whatsapp">
                                Whatsapp
                              </option>
                            </Input>
                            <Input
                              type="text"
                              name={`socials[${idx}].id`}
                              id={`socials.${idx}`}
                              onChange={props.handleChange}
                              value={props.values.socials[idx].id}
                              style={{
                                display: 'inline-flex',
                                width: '60%',
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))
                  : null}
                <Button
                  type="button"
                  outline
                  color="success"
                  onClick={() => arrayHelpers.push({ ...defaultNewSocial })}
                >
                  Add a channel
                </Button>
                <FormText color="muted">
                  Add channels such as Whatsapp, Facebook Messenger, and Line
                </FormText>
              </React.Fragment>
            )}
          />
          {props.errors.social ? (
            <Alert color="warning">{props.errors.social}</Alert>
          ) : null}
          <Row form>
            <br />
          </Row>
          <Row form>
            <h3>CRMs</h3>
          </Row>
          <Row form>
            <Col md={8}>
              <FormGroup>
                <Label for="hubSpotId">
                  <h5 style={{ display: 'inline' }}>HubSpot</h5>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href={hubspotBase + props.values.hubSpotId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link
                  </a>
                </Label>
                {props.errors.hubSpotId ? (
                  <Alert color="warning">{props.errors.hubSpotId}</Alert>
                ) : null}
              </FormGroup>
            </Col>
            {!props.partner ? (
              <React.Fragment>
                <Col md={8}>
                  <FormGroup>
                    <Label for="sfId">
                      <h5 style={{ display: 'inline' }}> Salesforce</h5>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        href={sfdcBase + props.values.sfId + sfdcBaseEnd}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </a>
                    </Label>
                    {props.errors.sfId ? (
                      <Alert color="warning">{props.errors.sfId}</Alert>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label for="zdId">
                      <h5 style={{ display: 'inline' }}>Zendesk</h5>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        href={zdBase + props.values.zdId + zdBaseEnd}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </a>
                    </Label>
                    {props.errors.zdId ? (
                      <Alert color="warning">{props.errors.zdId}</Alert>
                    ) : null}
                  </FormGroup>
                </Col>
              </React.Fragment>
            ) : null}
          </Row>
          <br />
          <Button type="submit" color="primary">
            Update
          </Button>
          {isUpdating ? <Spinner size="lg" color="secondary" /> : null}
          <br />
          <br />
          {hasError && <Alert color="danger">{errorMessage}</Alert>}
          {hasSubmitted && (
            <Alert color="success">Customer record submitted!</Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}
