import store from '../store'
import { updateCustomerDemoConfig } from './customer'
import demoConfigService from '../services/demoConfigService'

const GET_DEMOCONFIG = 'GET_DEMOCONFIG'
const GET_DEMOCONFIG_PENDING = 'GET_DEMOCONFIG_PENDING'
const GET_DEMOCONFIG_FULFILLED = 'GET_DEMOCONFIG_FULFILLED'

const UPDATE_DEMOCONFIG = 'UPDATE_DEMOCONFIG'
const UPDATE_DEMOCONFIG_PENDING = 'UPDATE_DEMOCONFIG_PENDING'
const UPDATE_DEMOCONFIG_FULFILLED = 'UPDATE_DEMOCONFIG_FULFILLED'
const SET_DEMOCONFIG = 'SET_DEMOCONFIG'

const initialState = {
  loading: false,
  hasErrored: false,
  hasSubmitted: false,
  errorMessage: null,
  demoConfig: null,
  isUpdating: false,
}

export const getDemoConfigs = (authToken, customer) => ({
  type: GET_DEMOCONFIG,
  payload: demoConfigService
    .getDemoConfig(authToken, customer.id)
    .then(async (config) => {
      customer.demoConfig = config
      store.dispatch(updateCustomerDemoConfig(customer))
      return {
        loading: false,
        hasError: false,
        errorMessage: null,
        hasSubmitted: false,
        demoConfig: { ...customer.demoConfig },
      }
    })
    .catch((err) => {
      console.log(err)
      return {
        loading: false,
        hasError: true,
        hasSubmitted: false,
        errorMessage: 'Unknown error. Please reach out to #help-owldemo ',
      }
    }),
})

export const updateDemoConfigData = (authToken, customer, config) => ({
  type: UPDATE_DEMOCONFIG,
  payload: demoConfigService
    .updateDemoConfig(authToken, customer.id, config)
    .then(async (config) => {
      customer.demoConfig = config
      store.dispatch(updateCustomerDemoConfig(customer))
      return {
        hasError: false,
        errorMessage: null,
        hasSubmitted: true,
        demoConfig: { ...customer.demoConfig },
      }
    })
    .catch((err) => {
      console.log(err)
      return {
        hasError: true,
        hasSubmitted: false,
        errorMessage: 'Unknown error. Please reach out to #help-owldemo ',
      }
    }),
})

export const setDemoConfigData = (demoConfig) => ({
  type: SET_DEMOCONFIG,
  payload: { ...demoConfig, hasSubmitted: false },
})

export function reduce(state = initialState, action) {
  switch (action.type) {
    case GET_DEMOCONFIG_PENDING:
      return {
        ...state,
        loading: true,
        hasError: false,
        errorMessage: null,
      }

    case GET_DEMOCONFIG_FULFILLED:
      return {
        ...state,
        isUpdating: false,
        ...action.payload,
      }

    case UPDATE_DEMOCONFIG_PENDING:
      return {
        ...state,
        isUpdating: true,
        hasError: false,
        hasSubmitted: false,
        errorMessage: null,
      }

    case UPDATE_DEMOCONFIG_FULFILLED:
      return {
        ...state,
        isUpdating: false,
        ...action.payload,
      }

    case SET_DEMOCONFIG:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
