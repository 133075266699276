import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import { useLanguageProvider } from '@demoeng/website-customization'
import { translations as optInTranslations } from '@demoeng/usecase-customer-opt-in'
import { translations as verifyTranslations } from '@demoeng/usecase-verify'
import { translations as messagingInTranslations } from '@demoeng/usecase-messaging'

import Routing from './Routing'

const oktaAuth = new OktaAuth({
  issuer: `https://${process.env.REACT_APP_OKTADOMAIN}/oauth2/default`,
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: process.env.REACT_APP_OKTACLIENTID,
  scopes: ['openid', 'profile', 'email', 'groups'],
})

const defaultLanguages = {
  en: { translation: {} },
  es: { translation: {} },
}

const translations = [
  ...optInTranslations,
  ...verifyTranslations,
  ...messagingInTranslations,
]

const App = () => {
  useLanguageProvider({ translations, defaultLanguages })
  return (
    <Router>
      <Security oktaAuth={oktaAuth}>
        <Routing />
      </Security>
    </Router>
  )
}

export default App
