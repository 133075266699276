import React from 'react'
import Moment from 'react-moment'
import { Spinner, Button } from 'reactstrap'

const FactorTotpRow = (props) => {
  const { factor, onDelete } = props
  return (
    <tr>
      <td>
        <Moment format="YYYY-MM-DD HH:mm" date={factor.dateCreated}></Moment>
      </td>
      <td>{factor.sid}</td>
      <td>{factor.friendlyName}</td>
      <td>{factor.status}</td>
      <td>
        {factor.isDeleting ? (
          <Spinner size="sm" color="secondary" />
        ) : (
          <Button
            color="danger"
            size="sm"
            onClick={() => onDelete(factor)}
            disabled={factor.isDeleting}
          >
            Delete
          </Button>
        )}
      </td>
    </tr>
  )
}

export default FactorTotpRow
