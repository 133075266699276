import React, { useState } from 'react'
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as yup from 'yup'
import QRCode from 'react-qr-code'

const createFactorSchema = yup.object().shape({
  friendlyName: yup
    .string()
    .required()
    .min(3, 'Friendly name must be at least 3 characters')
    .max(100, 'Friendly name cannot be longer than 100 characters'),
})

const validateFactorSchema = yup.object().shape({
  code: yup
    .string()
    .required()
    .min(6, 'code must be 6 digits')
    .max(6, 'code must be 6 digits')
    .matches(/[0-9]/, 'code must only contain digits'),
})

export default function CreateTotpFactorForm(props) {
  const {
    identity,
    token,
    createTotpFactor,
    cancelCreatingTotpFactor,
    verifyTotpFactor,
    isCreatingFactor,
    isVerifyingFactor,
    isCancelling,
  } = props

  const [newFactor, setNewFactor] = useState(null)

  const createFactorForm = useFormik({
    initialValues: {
      friendlyName: '',
    },
    validationSchema: createFactorSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        const { friendlyName } = values
        const { success, factor } = await createTotpFactor(
          identity,
          token,
          friendlyName,
        )
        if (success) {
          setNewFactor(factor)
        }
      } catch (err) {
        console.error(err)
      } finally {
        resetForm()
      }
    },
  })

  const verifyFactorForm = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validateFactorSchema,
    onSubmit: async (values, { setErrors, resetForm }) => {
      const { code } = values
      const { sid: factorSid } = newFactor
      const res = await verifyTotpFactor(identity, token, factorSid, code)
      if (res.error) {
        return setErrors({ code: res.error.message ?? 'Invalid token' })
      }
      resetForm()
    },
  })

  return (
    <>
      <Form
        onSubmit={createFactorForm.handleSubmit}
        name="createFactorForm"
        style={{ padding: '20px' }}
      >
        <Row form>
          <Col sm="12" md={{ size: 4, offset: 2 }}>
            <h5>Create a new TOTP Factor</h5>
          </Col>
        </Row>
        <Row form>
          <Col sm="12" md={{ size: 4, offset: 2 }}>
            <FormGroup>
              <Label
                for="friendlyName"
                hidden={!isCreatingFactor && isVerifyingFactor}
              >
                Friendly Name
              </Label>
              <Input
                type="text"
                name="friendlyName"
                hidden={!isCreatingFactor && isVerifyingFactor}
                value={createFactorForm.values.friendlyName}
                onChange={createFactorForm.handleChange}
                invalid={
                  createFactorForm.touched.friendlyName &&
                  Boolean(createFactorForm.errors.friendlyName)
                }
                disabled={isVerifyingFactor}
              />
              <FormFeedback>
                {createFactorForm.errors.friendlyName}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            {!isCreatingFactor && isVerifyingFactor ? (
              <p>Factor Created!</p>
            ) : (
              <>
                <Button
                  type="submit"
                  color="primary"
                  disabled={createFactorForm.isSubmitting || isVerifyingFactor}
                >
                  {createFactorForm.isSubmitting || isVerifyingFactor
                    ? 'Creating factor...'
                    : 'Create TOTP Factor'}
                </Button>
                {!isVerifyingFactor && !createFactorForm.isSubmitting && (
                  <Button
                    color="link"
                    className="text-secondary"
                    onClick={createFactorForm.resetForm}
                  >
                    reset
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>

      {isVerifyingFactor && (
        <Form
          onSubmit={verifyFactorForm.handleSubmit}
          name="verifyFactorForm"
          style={{ padding: '20px' }}
        >
          <Row form>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              <h6 className="text-secondary">
                Scan this QR code in an authenticator app:
              </h6>
            </Col>
          </Row>
          <Row form>
            <Col sm="12" md={{ size: 4, offset: 2 }}>
              <div style={{ padding: '10px' }}>
                {newFactor && (
                  <QRCode value={newFactor.binding.uri} size={124} />
                )}
              </div>
            </Col>
            <Col sm="12" md={{ size: 2 }}>
              <p>Or enter this code:</p>
              <p>{newFactor && <b>{newFactor.binding.secret}</b>}</p>
            </Col>
          </Row>
          <Row form style={{ paddingTop: '20px' }}>
            <Col sm="12" md={{ size: 4, offset: 2 }}>
              <FormGroup>
                <Label for="code" className="text-secondary">
                  TOTP from your authenticator app:
                </Label>
                <Input
                  type="text"
                  name="code"
                  placeholder="123456"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  disabled={isCancelling}
                  value={verifyFactorForm.values.code}
                  onChange={verifyFactorForm.handleChange}
                  invalid={
                    verifyFactorForm.touched.code &&
                    Boolean(verifyFactorForm.errors.code)
                  }
                />
                <FormFeedback>{verifyFactorForm.errors.code}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm="12" md={{ size: 6, offset: 2 }}>
              <Button
                type="submit"
                color="primary"
                disabled={verifyFactorForm.isSubmitting || isCancelling}
              >
                {verifyFactorForm.isSubmitting
                  ? 'Validating...'
                  : 'Validate Factor'}
              </Button>
              {newFactor && (
                <Button
                  style={{ marginLeft: '10px' }}
                  disabled={isCancelling || verifyFactorForm.isSubmitting}
                  onClick={() =>
                    cancelCreatingTotpFactor(identity, token, newFactor.sid)
                  }
                >
                  {isCancelling ? 'Cancelling' : 'Cancel'}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}
